@import "../../../vendor/css/variables-canusa.scss";

.full-w-img {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin: 0 auto;
	&--fullwidth {
		max-width: $content_max_width;
		margin-left: calc(50% - #{$content_max_width*0.5});
		margin-right: calc(50% - #{$content_max_width*0.5});
	}

	&--joined {
		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-bottom: -4rem;

			&:after {
				bottom: -4rem;
			}
		}
	}
}
