@import "../../../vendor/css/variables-canusa.scss";

.error-message {

	&__h1 {
		margin-bottom: 0.5rem;
	}

	&__h2 {
		color: $color-grey-400;
		display: flex;
		align-items: flex-start;
		flex-flow: row-reverse;
		justify-content: space-between;

		@media only screen and (min-width: $breakpoint_tablet_from){
			flex-flow: row;
			justify-content: flex-start;
		}
	}

	&__icon {
		color: $color-red-600;
		margin-right: 0.5rem;
	}

	&__text-block {
		margin-top: 1rem;

		.contact-box--modal,
		p + p {
			margin-top: 1rem;
		}
	}
}
