@import "../../../vendor/css/variables-canusa.scss";

.main-navigation {
	z-index: $zindex_nav_header;
	position: sticky;

	max-width: $content_max_width;
	margin: 0 auto;
	top: $header_mobile_height - 0.0625rem;

	box-shadow: $navigation_shadow;

	@media only screen and (min-width: $breakpoint_large_desktop_from) {
		top: $header_height;
	}

	.main-navigation__slide {
		flex-grow: 1;
	}

	&__nav-button {
		display: flex;
		width: 1.5rem;
		justify-content: center;
		align-items: center;

		height: $nav_header_height;
		background-color: $color-white;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		display: none;
	}
}

