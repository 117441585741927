@import "../../../vendor/css/variables-canusa.scss";

.travel-countdown {
	text-align: center;
	padding: 0 2rem;
	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: 0;
	}


	@media print {
		display: none !important;
	}

}
