@import "../../../vendor/css/variables-canusa.scss";

.preloader-wrapper {
  > div {
	animation-name: showItem;
	animation-duration: $transition_in_time;
	animation-play-state: paused;
	animation-fill-mode: forwards;

	height: auto;
	opacity: 0;
	max-height: 150px;
  }

  animation-delay: 0s;

  // amount of items that should be visible one after the other
  $childCount: 15;
  @for $i from 1 through $childCount {

	@if $i < $childCount {
	  > div:nth-child(#{$i}) {
		animation-delay: 0.15s * $i;
		animation-play-state: running;
	  }
	} @else {
	  // fallback for remaining items (hopefully not visible in viewport)
	  > div {
		animation-delay: 0.15s * $childCount;
		animation-play-state: running;
	  }
	}
  }
}

@keyframes showItem {
  0% {
	height: 150px;
	max-height: 150px;
  }
  50% {
	height: 100%;
	max-height: 300px;
  }
  100% {
	height: 100%;
	max-height: unset;
	opacity: 1
  }
}
