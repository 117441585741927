@import "../../../vendor/css/variables-canusa.scss";

.item {
	position: relative;
	border-radius: $size_border_radius;
	margin-bottom: 0.5rem;
	transition: box-shadow .3s linear;
	background-color: white;

	&--interactive {
		&:hover {
			position: relative;
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
		}

		.item-header {
			cursor: pointer;
			position: relative;

			&__clickarea {
				position: absolute;
				background-color: transparent;
				cursor: pointer;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
			}
		}
	}

	&--highlight {
		position: relative;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
	}

	&--open {
		z-index: 0;

		&.shadow {
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
		}

		.item-details {
			max-height: 8000px;
			border-top: 1px solid $color-grey-100;
			transition: max-height 1s ease-in-out;
		}

		.item-header {
			&:hover {
				background-color: #fcfcfc;
			}

			&__collapsible {
				.icon {
					transform: rotate(180deg);
				}
			}
		}

		.item-header-optionals__select-button {
			transition: color $transition_time $transition_ease, border-color $transition_time $transition_ease;
			color: $color-white;
			border-color: $color-white;
		}

		.item-header__note {
			&:hover {
				.icon {
					color: $color-white;
				}
			}
		}
	}

	&__trigger {
		display: none;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		box-shadow: none !important;
		border: solid 1px $color-grey-400;
		page-break-inside: avoid;

		.alternatives{
			display: none;
		}
	}
}

	.item-divider{
		font-weight: bold;
		text-transform: uppercase;
		padding: 1rem;
		font-size: 1rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding: 1rem 0;
		}

		small{
			font-size: 0.875rem;
			display: block;
			font-weight: normal;
			text-transform: none;
		}
}
