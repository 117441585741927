@import "../../../vendor/css/variables-canusa.scss";

.radiobutton {

	display: inline-block;
	position: relative;

	label {
		font-size: 1.45rem;
		line-height: inherit;
		transition: color .3s linear;
	}

	input:checked + label {
		color: $color-yellow-700;
	}

	input:disabled {
		cursor: default;
	}

	input:disabled + label {
		opacity: .5;
		pointer-events: none;
		cursor: default;
		color: $color-grey-900;
	}

	&.disabled {
		opacity: .5;
		pointer-events: none;
	}

	&.error {
		label {
			color: $color-red-600;
		}
	}

	&--signal {
		color: $color-yellow-700;
	}

	&--white {
		color: $color-white;
	}

	input {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	input:not(:checked) + label {

		:first-child {
			opacity: 0;
		}

		:last-child {
			opacity: 1;
		}
	}

	+ label {
		cursor: pointer
	}

	label {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		height: 100%;
		width: 100%;

		div {
			position: absolute;
			left: 0;
			top: 0;
		}

		.icon {
			height: 100%;
			width: 100%;
		}

		//unchecked icon
		:last-child {
			transition: opacity .3s ease;
		}

		//checked icon
		:first-child {
			position: absolute;
			top: 0;
			left: 0;
			transition: opacity .3s ease;
			z-index: 0;

			&:before{
				z-index: 1;
			}
			&:after{
				position: absolute;
				content: '';
				background-color: $color-white;
				width: 80%;
				height: 80%;
				border-radius: 100%;
				z-index: 0;
			}
		}
	}
}
