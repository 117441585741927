@import "../../../vendor/css/variables-canusa.scss";

.stage {
	position: relative;
	color: $color-white;

	.ie & {
		z-index: 1;
	}

	&__img {
		max-width: $content_max_width;
		@media screen and (min-width: $breakpoint_tablet_from) {
			max-height: 500px;
		}

		&:after {
			content: '';
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 188px;
			max-width: $content_max_width;

		}
	}

	&__content {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		position: absolute;
		width: auto;
		height: auto;
		right: 0;
		bottom: 0;
		left: 0;

		max-width: $content_max_width;

		margin: 0 auto;
		padding: 1rem;
		@media screen and (min-width: $breakpoint_tablet_from) {
			padding: 1.5rem
		}

		@media screen and (min-width: $breakpoint_desktop_from) {
			padding: 2rem
		}

		@media screen and (min-width: 1920px) {
			padding: 2rem
		}
	}

	&__contact-box {
		@media screen and (max-width: $breakpoint_tablet_to) {
			display: none;
		}
	}

	@media print {
		color: inherit;
		&__img {
			display: none !important;
		}

		&__content {
			position: relative;
			padding: 0;
			background-image: none;
			display: block;

			.travel-time {
				margin-bottom: 3rem;
			}


		}

		&__contact-box {
			padding-right: 0;
			width: auto;
			.contact-box {
				margin-left: auto;
				justify-content: flex-end;
			}
		}
	}
}
