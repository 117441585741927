@import "../../../../vendor/css/variables-canusa.scss";

.item-select-option {

	&__details {
		display: flex;
		width: 100%;
		justify-content: space-between;

		@media only screen and (min-width: $breakpoint_desktop_from) {
			width: auto;
		}
	}

	&__option {
		border-radius: 2px;
		border: 1px solid $color-grey-100;

		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		padding: 1rem;

		cursor: pointer;

		@media only screen and (max-width: $breakpoint_desktop_to) {
			.tooltip-wrapper {
				width: 100%;
			}
		}

	}

	&__selector-checkbox {
		height: 24px;
		margin-left: 2rem;
	}

	&__selector-button {
		margin-left: 2rem;
	}
}
