@import "../../../vendor/css/variables-canusa.scss";

.item-group {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;

	& + & {
		margin-top: 1.5rem;

		&--documents {
			margin-top: 4rem;
		}
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		display: block !important;

		&-extras--print {
			page-break-inside: avoid;
		}

		&--with-background {
			padding-bottom: 2rem;
		}
	}
}


