@import "../../../vendor/css/variables-canusa.scss";

.collapsible {
	position: relative;
	display: block;
	border-radius: 2px;
	border-bottom: 1px solid $color-grey-100;
	margin-bottom: 0.25rem;

	.head {
		background-color: $color-white;
		display: flex;
		align-items: center;
		border-radius: 2px;
		position: relative;

		.arrow {
			position: absolute;
			font-size: 1.5rem;
			right: 1.5rem;
			pointer-events: none;

			.open {
				display: block;
			}

			.close {
				display: none;
			}
		}

		> div > label {
			padding-right: 1rem;
		}

		.toggle {
			display: block;
			position: absolute;
			bottom: 1rem;
			right: 1rem;
			cursor: pointer;

			.close {
				display: none;
				padding-bottom: 0;
			}
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {

			> div > label {
				padding-right: 3rem;
			}


			.toggle {
				display: none;
			}
		}
	}

	&:hover {

		.head {
			.arrow {
				color: $color-gold-700;
			}
		}
	}

	.content {
		background-color: $color-white;
		border-top: 1px solid $color-grey-100;
		position: relative;
		display: block;
		padding: 0 2rem;
		overflow: hidden;
		max-height: 0;

		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

		.inner-content {
			padding: 1.5rem 0;

			p {
				margin-top: 1rem;
			}
		}

	}

	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"]:checked {

		+ .head {

			.arrow {
				.open {
					display: none;
				}

				.close {
					display: block;
				}
			}

			.toggle {
						.open {
					display: none;
				}

				.close {
					display: block;
				}
			}

			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		+ .head + .content {
			max-height: 8000px;
			border-bottom-left-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	}

	&.no-content {

		.head {
			cursor: default;

			.arrow {
				display: none;
			}
		}
	}

	&--shadow-on-hover {
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
		&:hover {
			z-index: 1;
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
		}

		&.no-content {
			&:hover {
				z-index: 1;
				box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}

	&.force-toggle-arrow {
		.arrow {
			display: block;
		}

		.toggle {
			display: none;
		}
	}
}
