@import "../../../vendor/css/variables-canusa.scss";

.digital-offer-optionals-content {
	max-width: $content_max_width;

	&__summary {
		background-color: $color-grey-900;
	}

	.contact-box {
		@media screen and (min-width: $breakpoint_tablet_from), print {
			display: none;
		}
	}

	&__intro {
		margin-bottom: 2rem;
	}

	&__intro-headline {
		margin-bottom: 1rem;
	}

	&__main {
		width: 100%;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------
	@media print {
		&__summary {
			page-break-inside: avoid;
		}
	}
}
