@import "../../../vendor/css/variables-canusa.scss";

.price-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: $color-white;
	background-color: $color-grey-900;

	border-radius: $size_border_radius $size_border_radius 0 0;
	margin-bottom: 0;

	padding: 1.75rem 1rem;

	transition: bottom $transition_time $transition_ease;
	position: sticky;
	bottom: -85px;
	z-index: $zindex_total-price;

	.ie & {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		min-height: 0;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		border-radius: $size_border_radius;

		.price-bar--border-top {
			border-radius: $size_border_radius $size_border_radius 0 0;
		}
	}

	&--optional {
		margin-bottom: 0.5rem;
	}

	&__label {
		margin-right: 1rem;
	}

	&__price {
		margin-left: auto;
		white-space: nowrap;
		height: 100%;
		align-self: flex-start;
		@media screen and (min-width: $breakpoint_tablet_from) {
			align-self: center;
		}
	}

	&__mainlabel {
		margin-right: 0.5rem;
		float: left;
	}

	&__sublabel {
		color: $color-grey-400;
		font-weight: normal;
		text-transform: none;
		white-space: nowrap;
	}
	&--slide-in {
		position: sticky;
		bottom: -2px;
	}
}

.total-price {

	&__container {
		margin-top: 2rem;
		display: contents;
		background-color: $color-grey-900;
	}

	&__last-call {
		color: $color-white;
		background-color: $color-grey-900;
		padding: 0 1rem 1.5rem;
	}

	&__last-call-headline {
		padding-top: 1rem;
		margin-bottom: 0.5rem;
	}

	&__last-call-badge {
		margin-right: 0.5rem;
	}
}

//--------------------------------------------------- PRINT ONLY ---------------------------------------------------
.price-bar {
	@media print {
		page-break-before: avoid;
		position: relative;
		bottom: 0;
	}
}
