@import "../../../../../vendor/css/variables-canusa.scss";

.item-details {

	/* addon item default (checkbox)*/
	&-addon {
		display: flex;
		position: relative;
		flex-flow: row nowrap;
		align-items: center;
		padding: 1.5rem 0 1.25rem;

		.tooltip-wrapper {
			line-height: 0;
		}

		&__selection {
			.touch-area {
				padding: 1rem 1rem 1rem 0;
				@media only screen and (min-width: $breakpoint_tablet_from) {
					padding: 0;
				}
			}
		}

		.checkbox {
			input {
				padding: 0;
			}
		}

		&__price {
			margin-left: 1rem;
			margin-right: 1rem;
			min-width: 55px;
			text-align: right;
		}

		&__name {
			padding-right: 1.75rem;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				padding-right: unset;
				min-width: 360px;
			}
		}

		&__info {
			position: absolute;
			right: 0;

			.touch-area {
				padding: 1rem 0 1rem 1rem;
				@media only screen and (min-width: $breakpoint_tablet_from) {
					padding: 0;
				}

				.icon {
					font-size: 1rem;
				}
			}
		}

		&__summary {
			padding: 1.5rem 0 0;
		}

		&__tooltip {
			text-align: left;

			p {
				margin-bottom: 0;
			}

			p + p {
				margin-top: 0.25rem;
			}

			ul {
				margin-left: 1rem;
			}
		}

		.mobile-wrapper {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			margin-left: 0;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				margin-left: 76px;

			}
		}

		/* addonItem with select*/
		&.with-select {

			display: flex;
			position: relative;
			flex-flow: column-reverse nowrap;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				position: relative;
				flex-flow: row nowrap;
				align-items: center;
				padding: 1rem 0;
			}

			.item-details-addon {

				&__selection {
					min-width: 6.5rem;
					width: 100%;
					@media only screen and (min-width: $breakpoint_tablet_from) {
						width: auto;
					}
				}
			}

			.mobile-wrapper {
				margin-bottom: 1rem;
				justify-content: flex-start;
				width: 100%;
				margin-left: 0;
				@media only screen and (min-width: $breakpoint_tablet_from) {
					margin-bottom: unset;
				}

				.item-details-addon {
					&__price {
						margin-left: 0;
						display: none;
						@media only screen and (min-width: $breakpoint_tablet_from) {
							margin-left: 1rem;
							display: block;
						}
					}
				}
			}
		}

		&:first-child {
			padding-top: 0;
		}
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		&-addon{
			page-break-inside: avoid;
		}
	}
}

