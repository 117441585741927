@import "../../../vendor/css/variables-canusa.scss";

.price-guarantee {
	margin-top: 1rem;

	&__headline {
		margin-bottom: 2rem;
		text-align: center;
	}

	&__delimiter {
		border-top: 1px solid #f0f0f0;
		margin-bottom: 2rem;
	}

	&__card-wrapper {
		display: flex;
		flex-flow: column nowrap;

		@media screen and (min-width: $breakpoint_tablet_from) {
			flex-flow: row nowrap;
		}
	}

	&__card {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
	}

	&__card + &__card {
		margin: 2rem 0 0 0;
		@media screen and (min-width: $breakpoint_tablet_from) {
			margin: 0 0 0 2rem;
		}
	}

	&__icon {
		font-size: 2rem;
		line-height: 1;
	color: $color-red-700;
		margin-bottom: 0.5rem;
	}

	&__subheadline {
		text-align: center;
		margin-bottom: 0.5rem;
	}

	&__copy {
		text-align: center;
	}


	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		page-break-inside: avoid;
		&__card-wrapper {
			flex-flow: row nowrap;
		}

		&__card + &__card {
			margin: 0;
		}
	}
}
