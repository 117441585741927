@import "../../../vendor/css/variables-canusa.scss";

$buttonWidth: 40px;
$buttonHeight: 56px;

.alternatives {
  display: flex;
  position: relative;
  width: 100%;

  &__dotted-line {
	position: absolute;
	width: 100%;
	top: -2px;

	&:before {

	  transition: opacity 0.1s;
	  content: '';
	  position: absolute;
	  left: $buttonWidth;
	  bottom: -2px;
	  width: calc(100% - #{$buttonWidth});
	  height: 1px;
	  background-color: rgba(0, 0, 0, 0);
	  // using 24.5% to prevent "dotted-line-fallout" on firefox
	  background-image: linear-gradient(to right, $color-gold-400 24.5%, rgba(255, 255, 255, 0) 0%);
	  background-position: bottom;
	  background-size: 4px 1px;
	  background-repeat: repeat-x;
	}
  }

  &__remaining-items {
	width: 140px;
	height: 80px;
	border: 1px solid $color-grey-100;
	display: inline-grid;
	justify-items: center;
	align-items: center;
	color: $color-grey-800;
  }
}

.alternatives-slider {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  overflow: hidden;

  &__btn {
	background-color: $color-white;
	box-shadow: $medium_shadow;
	width: $buttonWidth;
	height: $buttonHeight;
	display: flex;
	justify-content: center;
	font-size: 1rem;
  }


  &__slide + &__slide {
	margin-left: 1rem;
  }

  &__arrow-inner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: $buttonWidth;
	height: $buttonHeight;
	background-color: $color-white;
	color: $color-grey-900;
	padding: 0.5rem;
		&:hover {
			color: $color-gold-700;
		}
		&--expanded {
			background-color: $color-grey-900;
			color: $color-white;
			&:hover {
				color: $color-white;
				background-color: $color-grey-800;
			}
		}
  }
}
