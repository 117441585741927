@import "../../../vendor/css/variables-canusa.scss";

$block: '.checkout-progress';

#{$block} {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: center;
	width: 50%;
	margin: 0 auto 4rem;
	transform: translateY(-25%);

	&__step {
		border-radius: 50%;
		width: 1.5rem;
		height: 1.5rem;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;

		&--highlight {
			color: $color-gold-700;
			background-color: $color-gold-250;
			border: 1px solid $color-gold-250;
			font-weight: 700;
		}

		&--coming {
			background-color: $color-white;
			color: $color-gold-700;
			border: 1px solid $color-gold-400;
		}

		&--passed {
			color: $color-gold-700;
		}
	}

	&__dotted {
		background-position: bottom;
		background-size: 4px 1px;
		background-repeat: repeat-x;
		padding-bottom: 2px;
		height: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 2px;
		flex-grow: 1;

		&--coming {
			background-image: linear-gradient(to right, $color-gold-700 24%, rgba(255, 255, 255, 0) 0);
		}
	}

	&__solid {
		height: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 2px;
		flex-grow: 1;

		&--passed {
			background-color: $color-gold-400;
		}
	}
}
