@import "../../../vendor/css/variables-canusa.scss";

$navigationItem: '.navigation-item';
#{$navigationItem} {

	$highlightHeight: 4px;

	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		width: 100%;
		height: $highlightHeight;
		background-color: $color-gold-700;
		position: absolute;
		bottom: 0;

		transition: transform .3s;
		transform: translateY(100%);
	}

	&--active {
		&:after {
			transform: translateY(0);
		}

		#{$navigationItem}__navigation-button {
			&:hover {
				color: $color-gold-600;
				background-color: $color-white;
			}
		}
	}

	&__navigation-button {
		height: $nav_header_height;
		background-color: $color-white;

		&:hover {
			color: $color-gold-600;
			background-color: $color-white;
		}
	}
}
