@import "../../../vendor/css/variables-canusa.scss";

.badge {
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 2px 4px;
	margin-top: 1px;
	margin-bottom: 0.5rem;

	width: fit-content;

	border: 2px solid currentColor;
	border-radius: 3px;

	font-size: 0.75rem;

	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;

	-webkit-user-select: none; /* Safari 3.1+ */
	-moz-user-select: none; /* Firefox 2+ */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Standard syntax */

	white-space: nowrap;

	transition: color $transition_time, border $transition_time, background-color $transition_time;

	&.default {
		border: 2px solid $color-gold-700;
		color: $color-gold-700;

		&.badge--inverted {
			color: $color-white;
			background-color: $color-gold-700;
		}
	}

	&.important {
		border: 2px solid $color-red-600;
		color: $color-red-600;

		&.badge--inverted {
			color: $color-white;
			background-color: $color-red-600;
		}
	}

	&.signal {
		border: 2px solid $color-yellow-700;
		color: $color-yellow-700;

		&.badge--inverted {
			color: $color-white;
			background-color: $color-yellow-700;
		}
	}

	&.white {
		border: 2px solid $color-white;
		color: $color-white;

		&.badge--inverted {
			color: $color-grey-900;
			background-color: $color-white;
		}
	}

	&__icon {
		padding-right: 0.25rem;
	}

	&--small {
		text-transform: none;
		font-size: 0.75rem;
		padding: 1px 0.25rem;
		line-height: 1.2;
		margin-bottom: 2px;
	}
}
