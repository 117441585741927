@import "../../../vendor/css/variables-canusa.scss";

/* TODO: ist das nötig? */
.input + .input {
  margin-top: 1rem;
}

.input {
  position: relative;
  display: inline-block;

  input {
	text-align: left;
	background-color: white;
	border: 1px solid $color-gold-400;
	border-radius: 2px;

	&:focus::-webkit-input-placeholder {
	  color: transparent;
	}

	&:focus:-moz-placeholder {
	  color: transparent;
	}

	/* FF 4-18 */
	&:focus::-moz-placeholder {
	  color: transparent;
	}

	/* FF 19+ */
	&:focus:-ms-input-placeholder {
	  color: transparent;
	}

	/* IE 10+ */

	margin: 0;


	&[type="date"] {

	  font-family: $fontFamily;
	  max-height: 46px;
	  max-width: 100%;

	}
  }

	&__error-message{
		font-size: 12px;
		color: $color-red-600;
	}

  label {
	z-index: 1;
	position: absolute;
	top: 0;
	left: .875rem;
	color: $color-gold-700;
	transform: translateY(-50%);
	font-size: 0.75rem;
	line-height: 1;

	&:after {
	  content: '';
	  position: absolute;
	  top: calc(50% + 1px);

	  transform: translateY(-50%);
	  left: -6px;
	  display: block;
	  height: 3px; // input border-width + 2px for rendering issues
	  width: calc(100% + 12px);
	  background-color: white;
	  z-index: -1;
	}
  }

  &.disabled {
	opacity: 0.6;
	pointer-events: none;
  }

  &.error {
	label {
	  color: $color-red-600;
	}

	input {
	  border-color: $color-red-600;
	}
  }

  &.theme-dark {
	.field-label {
	  color: $color-grey-400;

	  &:after {
		background-color: $color-grey-900;
	  }
	}

	&.error {
	  label {
		color: $color-red-600;
	  }
	}

	input {
	  background-color: $color-grey-900;
	  color: $color-grey-400;
	}

  }

  &.readonly {
	label {
	  &:after {
		background-color: #fcfcfc;
	  }
	}
  }

  &.field-error {
	label {
	  color: $color-red-600;
	}
  }

  &--full-width {
	width: 100%;

	input {
	  width: 100%;
	}
  }
}

input {
  // remove autofill background on firefox
  filter: grayscale(21%);

  // remove autofill background on webkit browsers
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
  }
}
