@import "../../../vendor/css/variables-canusa.scss";

.revision-selection {
	display: flex;
	flex-flow: column nowrap;

	margin: 0;

	&__headcontent {
		display: flex;
		flex-flow: column nowrap;
		margin: 0;
	}

	&__headline {
		margin-bottom: 0.5rem;
	}

	&__subline {
		color: $color-grey-400;
		margin-bottom: 1rem
	}

	&__badge {
		position: relative;
		text-transform: uppercase;
		line-height: 1.2;
		margin-left: 0.5rem;
		margin-right: 1rem;
		top: -1px;
	}

	&__delimiter {
		font-size: 1rem;
		margin: 0 0 1rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin: 0;
		}
	}

	&__item {
		display: flex;
		flex-flow: row nowrap;

		border-top: 1px solid $color-grey-100;
		padding: 1rem 1rem;

		width: calc(100% + 2rem);
		margin-left: -1rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			align-items: center;
			justify-content: space-between;
			border: 1px solid $color-grey-100;
			margin-top: 1rem;
			padding: 1rem;

			width: 100%;
			margin-left: 0;
		}

		color: $color-grey-900;
		cursor: pointer;

		transition: box-shadow 0.35s;

		&:hover {
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
		}

		&--selected {
			border: 1px solid $color-gold-400;
			box-shadow: none;
			pointer-events: none;
			cursor: default;

			.revision-selection__radio-button {
				color: $color-gold-700
			}
		}


		&__info-icon {
			color: $color-gold-400;
		}
	}

	&--no-hover {
		opacity: 0.5;
		cursor: default;

		&:hover {
			box-shadow: none;
		}
	}

	&__radio-button {
		font-size: 1.5rem;
		padding: 0 1rem 0 0;
		color: $color-grey-400
	}

	&__content {
		display: flex;
		width: 100%;
		flex-flow: column nowrap;

		> div + div {
			margin-top: 1rem;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			flex-flow: row nowrap;
			justify-content: space-between;

			> div + div {
				margin-top: 0;
			}
		}
	}

	&__revision-number {
		min-width: 10rem;
		width: 100%;
	}

	&__revision-number-column {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}



	&__small-subline {
		margin-top: 0.25rem;
		color: $color-grey-400;
	}

	.revision-selection__item + .revision-selection__item {

		&:last-child {
			border-bottom: 1px solid $color-grey-100;
			margin-bottom: 2rem;
		}

	}

	&__year-divider {
		border-top: 1px solid $color-grey-100;
		padding: 2rem 0 1rem 1rem;

		width: calc(100% + 2rem);
		margin-left: -1rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			border-top: none;
			width: 100%;
			margin-left: 0;
		}
	}


	&__inactive_revision {
		margin-top: 0;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-top: 1rem;
		}

		.revision-selection__info-icon {
			.tooltip-wrapper {
				pointer-events: none;
			}
		}

		.revision-selection__item {
			margin-top: 0;
			opacity: 0.5;
			box-shadow: none;
			pointer-events: none;
			cursor: default;
		}

		&:last-child {
			border-bottom: 1px solid $color-grey-100;
			margin-bottom: 3rem;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				border-bottom: none;
			}
		}
	}
}
