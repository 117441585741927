@import "../../../vendor/css/variables-canusa.scss";

.faq {
	/*
		TODO: Dropshadow on Hover & Padding vom Collapsible verhält sich beim FAQ anders ?
		TODO: marked for refactoring
	*/
	background-color: $color-white;

	h1 {
		padding: 0 1rem;
		margin-bottom: 0;
	}

	h2 {
		color: $color-grey-400;
		font-size: 1.25rem;
		padding: 2.75rem 1rem 1.5rem 1rem;
		margin-top: 0;
		margin-bottom: 0;
		border-bottom: 1px solid $color-grey-100;
	}

	h3 {
		padding: 1.5rem 1rem;
		font-size: 0.875rem;
		text-transform: none;
	}

	padding: 0 1rem;
	margin-bottom: 2.5rem;

	&__entry {
		.head {
			cursor: pointer;

			h3 {
				font-size: 0.875rem;
				padding-left: 1rem;
				padding-right: 4rem;
				@media only screen and (min-width: $breakpoint_tablet_from) {
					padding-right: 1rem;
				}
			}
		}

		.content {
			border-top: none;
			padding: 0;

			.inner-content {
				padding-left: 1rem;
				padding-right: 1rem;
				font-size: 0.875rem;
				margin-bottom: 1.5rem;

			}
		}
	}

  &__preloader{
	height: 100%;
	padding: 5rem;
  }
}
