@import "../../../vendor/css/variables-canusa.scss";
@import "../../../vendor/css/zindex-canusa.scss";

.consent {
	&.show-consent {
		bottom: 0;
	}
}


.cookie-consent {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: $zindex_consent;

	display: none;
	justify-content: center;
	@media screen and (min-width: $breakpoint_tablet_from) {
		align-items: center;
	}

	&--hidden{
		display: none;
	}

	&.form {
		background: transparent;
		color: $color-grey-900;

		& label {
			align-items: unset;
		}
	}

	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		@media screen and (min-width: $breakpoint_tablet_from){
			position: absolute;
		}

		background-color: rgba(0, 0, 0, 0.8);
	}

	&__headline {
		font-size: 24px;
		//font-weight: $bold;
		margin-bottom: 1.5rem;
	}



	&__checkbox-wrapper {
		display: flex;
		flex-flow: column nowrap;
		margin-top: 4rem;

		@media screen and (min-width: $breakpoint_tablet_from){
			flex-flow: row nowrap;
			margin-bottom: 2.5rem;
		}

		.form_field {
			align-items: unset;
		}
	}

	&__scroll-wrapper {
		height: calc(100% - 169px);
		overflow: scroll;
		margin-bottom: 1rem;

		@media screen and (min-width: $breakpoint_tablet_from){
			height: auto;
			overflow: auto;
		}

		> p{
			font-size: 1rem;
			line-height: 1.5;
		}
	}

	&__close-btn {

		position: absolute;
		right: 1.5rem;
		top: 1.5rem;
		font-size: 1rem;

		cursor: pointer;

		&:hover {
			opacity: 0.8;

		}
	}

	&__content-wrapper {
		position: relative;
		width: 794px;
		height: auto;
		padding: 1.5rem 1.5rem 0 1.5rem;
		margin: 1rem;
		background-color: $color-white;

		@media screen and (min-width: $breakpoint_tablet_from){
			padding: 1.5rem;
		}
	}

	&__bottom-wrapper {
		display: flex;
		flex-flow: column-reverse;
		align-items: center;

		@media screen and (min-width: $breakpoint_tablet_from){
			justify-content: space-between;
			flex-flow: row;
			.consent__link {
				font-size: 14px;
				margin-bottom: 0;
			}
		}

		.consent__link {
			margin-bottom: 1rem;
		}
	}

	&__button-wrapper {
		display: flex;
		flex-flow: column-reverse;
		width: 100%;

		@media screen and (min-width: $breakpoint_tablet_from){
			flex-flow: row;
			justify-content: flex-end;

			> button {
				margin-left: 0.5rem;
				margin-bottom: 1rem;
			}
		}

		> button {
			margin-bottom: 1rem;
		}

	}


	&.show-consent {
		display: flex;
		overflow: auto;
	}

}
