@import "../../../vendor/css/variables-canusa.scss";

$block: '.checkout-navigation';

#{$block} {
	margin-top: 0;
	@media screen and (min-width: $breakpoint_tablet_from), print {
		margin-top: 2rem;
	}

	&__button-wrapper {

		display: flex;
			flex-flow: column-reverse nowrap;
			align-items: center;
			padding: 1rem 0;

		.button + .button {
			margin-bottom: 1rem;
		}

		#{$block}--reverse-order & {
			flex-flow: column nowrap;
			.button + .button {
				margin-top: 1rem;
			}

			@media screen and (min-width: $breakpoint_tablet_from), print {
				flex-flow: row nowrap;
			}
		}

		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-flow: row nowrap;
		}

		.button--fit {
			width: 100%;
			@media screen and (min-width: $breakpoint_tablet_from), print {
				width: auto;
			}
		}


		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}
}
