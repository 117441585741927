@import "../../../vendor/css/variables-canusa.scss";

.participants {
	display: block;
	margin-bottom: 1rem;
	//overflow: hidden;
	position: relative;

	&__head {
		display: flex;
		margin-bottom: 1rem;

		@media screen and (min-width: $breakpoint_tablet_from), print {
			margin-bottom: 1.5rem;
		}
	}

	&__disclaimer {
		margin-top: 1.5rem;
	}

	&__list {
		counter-reset: participant-count;
	}

	&__edit {
		position: absolute;
		right: 1rem;
		top: 1rem;
		cursor: pointer;

		font-size: 1rem;
	}

	.participant {
		display: flex;
		width: 100%;
		align-items: flex-start;
		padding: 1rem 0;

		&:not(:last-child) {
			border-bottom: 1px $color-grey-100 solid;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&:first-child {
			padding-top: 0;
		}

		&__name {
			display: flex;
			flex: 1 1 0%;
			@media screen and (min-width: $breakpoint_tablet_from), print {
				flex: 0 1 100%;
				padding-right: 1rem;
			}

			&--inline {
				display: inline-block;
				margin-left: 0.25rem;
			}
		}

		&__birthdate {
			display: none;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				min-width: 130px;
				display: block;
			}
		}
	}
}
