@import "../../../vendor/css/variables-canusa.scss";

.booking-box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem 1rem;

  color: $color-white;
  background-color: $color-grey-900;

	flex-flow: column-reverse;

	&__wrapper {
		width: 100%;
		display: block;
	}

	&__wrapper + &__wrapper {
		margin: 0 0 1rem 0;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		flex-flow: row;
		&__wrapper + &__wrapper {
			margin: 0 0 0 1rem;
		}
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		display: none;
	}
}
