@import "../../../vendor/css/variables-canusa.scss";

.travel-time {
	&__date {
		display: flex;
		align-items: center;
		margin-top: 2px;
		margin-bottom: -2px;
	}

	&__icon {
		font-size: 1.5rem;
		margin-right: 0.5rem;
	}
}
