@import "../../../vendor/css/variables-canusa.scss";

$contact-box: '.contact-box';

$photo-size: 4rem;
$photo-size-mobile: 4.875rem;

#{$contact-box} {

	position: relative;
	display: flex;
	align-items: center;

	&__photo {
		position: relative;
		background-color: $color-white;
		min-width: $photo-size;
		width: $photo-size;
		height: $photo-size;
		box-shadow: $slight_shadow;
		border-radius: 50%;
		overflow: hidden;
		border: 4px solid $color-white;
		// Safari overflow hidden bugfix
		-webkit-mask-image: -webkit-radial-gradient(white, black);
	}

	&__name {
		margin-bottom: 0.25rem;
		position: relative;
	}

	&__option {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 0.25rem;
		}

		.icon {
			width: 1rem;
			height: 1rem;
		}
	}

	/* APPEARANCES */
	&--horizontal {
		flex-direction: row;
		background-color: transparent;

		#{$contact-box}__options {
			padding-left: 1rem;
			margin-left: 1rem;
			border-left: 1px solid rgba($color-white, 0.4);

		}

		#{$contact-box}__photo {
			position: absolute;
			right: 100%;
			display: none;
			margin-right: 1rem;

			.ie & {
				top: -0.5rem;
			}

			@media screen and (min-width: $breakpoint_desktop_from) {
				display: block;
			}
		}
	}

	&--portrait {
		background-color: $color-white;
		flex-direction: column;
		text-align: center;
		padding: 1rem;
		margin: 3rem 0 2rem;
		border-radius: $size_border_radius;
		box-shadow: $slight_shadow;


		@media screen and (min-width: $breakpoint_tablet_from) {
			padding: 1.5rem 1rem;
			display: none;
		}

		&#{$contact-box}--with-picture {
			margin-top: 2rem;
		}

		#{$contact-box}__option {
			justify-content: center;
		}

		#{$contact-box}__photo {
			transform: translateY(calc(-50% - 1rem));
			@media screen and (min-width: $breakpoint_tablet_from) {
				transform: translateY(calc(-50% - 1.5rem));
			}
		}

		#{$contact-box}__signature {
			margin-bottom: 1rem;
		}

		#{$contact-box}__photo + #{$contact-box}__signature {
			margin-top: calc((#{-$photo-size} / 2));
		}
	}

	&--modal {
		flex-direction: column;
		background-color: rgba($color-grey-100, 0.3);
		padding: 1.5rem 2rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			flex-flow: row nowrap;
		}

		#{$contact-box} {

			&__signature {
				@media only screen and (min-width: $breakpoint_tablet_from) {
					padding-right: 2rem;
					margin-right: 2rem;
					border-right: 1px solid $color-grey-100;
				}
			}

			&__description {
				display: none;
				@media only screen and (min-width: $breakpoint_tablet_from) {
					display: flex;
				}
			}

			&__options {
				.link__label {
					margin-left: 0.25rem;
				}
			}

			&__option + #{$contact-box}__option {
				margin-top: 0.5rem;
			}

			&__photo {
				display: none;

				@media only screen and (min-width: $breakpoint_tablet_from) {
					display: block;
					min-width: $photo-size-mobile;
					width: $photo-size-mobile;
					height: $photo-size-mobile;
					margin-right: 2rem;
				}
			}

			@media screen and (min-width: $breakpoint_tablet_from), print {
				margin: 1rem 0 0 0;
				padding: 0;
				> div {
					display: none;
				}
			}
		}
	}

	@media print {
		&__photo {
			display: none;
		}

		&--portrait {
			display: none;
		}
	}
}
