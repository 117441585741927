@import "../../../vendor/css/variables-canusa";

.error-boundary {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;

  padding: 3rem;
  width: 100%;


  a {
	display: block;
	width: auto;
  }

  &__headline {
	margin-bottom: 0.5rem;
  }

  &__copy {
	max-width: 300px;

  }

  &__button {
	display: inline-block;
	margin-top: 1rem;
  }

}