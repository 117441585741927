@import "../../../vendor/css/variables-canusa.scss";

.user-profile {

	display: flex;
	flex-flow: column nowrap;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding-bottom: 1rem;

	&__name {
		margin-bottom: 2rem;
		max-width: 320px;

		@media screen and (min-width: $breakpoint_tablet_from){
			margin-top: 2rem;
		}
	}

	&__offer-headline {
		width: 100%;
		margin-bottom: 0.5rem;

		&:before, &:after {
			height: 2px;
		}
	}

	&__offer-number {
		margin-bottom: 2rem;
	}

	&__button{
		margin-bottom: 0.5rem;
	}

	&__dotted-line{
		width: 100%;
		margin-top: -1rem;
		margin-bottom: 2rem;
	}

}
