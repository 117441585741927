@import "../../../../vendor/css/variables-canusa.scss";

.item-group + .item-group-header {
	margin-top: 2rem;
}

.item-group-header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	overflow: hidden;
	padding: 1.5rem 1rem;
	@media only screen and (min-width: $breakpoint_tablet_from) {
		border-radius: $size_border_radius;
	}
	margin-bottom: 0.5rem;

	color: $color-white;
	background-color: $color-grey-900;

	> * {
		margin-right: 1rem;
	}

	> :last-child {
		margin-right: 0;
	}

	@media screen and (min-width: $breakpoint_tablet_from) {
		padding: 1rem 1.5rem;
		min-height: 88px;
	}

	&__price {
		margin-left: auto;
		white-space: nowrap;
		height: 100%;
		align-self: flex-start;
		@media screen and (min-width: $breakpoint_tablet_from) {
			align-self: center;
		}
	}

	&__mainlabel {
		margin-right: 0.5rem;
		float: left;
	}

	&__badge {
		display: flex;
		margin-left: auto;
		margin-right: unset;
		align-self: baseline;

		@media screen and (min-width: $breakpoint_tablet_from) {
			align-self: unset;
			margin-left: unset;
			margin-right: auto;
		}
	}

	&__button {
		min-width: 283px;

		@media screen and (max-width: $breakpoint_tablet_to) {
			width: 100%;
			margin-top: 1rem;
		}
	}

	&__image {

		.img {
			width: 100%;
			@media screen and (min-width: $breakpoint_desktop_from) {
				max-width: 1920px;
			}
		}

		@media screen and (min-width: $breakpoint_tablet_from) {
			margin-bottom: -7.75rem;
		}
	}

	&--type-optional {
		background: none;
		color: inherit;
		padding: 0 1rem;

		@media screen and (min-width: $breakpoint_tablet_from) {
			padding: 0;
		}

		min-height: unset;
		justify-content: unset;

		font-size: 1rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	&--optionals {
		.ie & {
			padding-top: 0rem;
			padding-bottom: 0rem;
			min-height: 0;
			justify-content: flex-start;
		}
	}

	&--with-bg {
		background-image: url('../../../../assets/static/map_dots.svg');
		background-attachment: fixed;
		background-size: inherit;
		background-repeat: no-repeat;

		.ie & {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			min-height: 0;
		}
	}

	&--documents {
		flex-wrap: wrap;
		margin-bottom: 0;

		@media screen and (min-width: $breakpoint_tablet_from) {
			flex-wrap: nowrap;
		}
	}


	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		padding: 1.25rem 1.5rem;
		border: .25rem solid $color-grey-900;
		-webkit-print-color-adjust: exact;

		&--type-optional {
			padding: 0;
			border: 0;
		}

		&__button,
		&__image {
			display: none;
		}
	}
}
