@import "../../../vendor/css/variables-canusa";

.faq-page {

  .faq.box {
    width: 100vw;
    @media screen and (min-width: $breakpoint_tablet_from) {
      width: inherit;
    }
  }

  .faq__entry {

    .head {
      h3 {
        transition: color $transition_time ease;
      }
    }

    .head {
      &:hover {
        h3 {
          color: $color-gold-700
        }
      }
    }

    .content {
      .inner-content {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
