@import "../../../vendor/css/variables-canusa.scss";

.optionals-content {
	max-width: $content_max_width;
	margin: 0 auto;

	.contact-box {
		@media screen and (min-width: $breakpoint_tablet_from), print{
			display: none;
		}
	}

	&__intro {
		margin-bottom: 2rem;
	}

	&__intro-headline {
		margin-bottom: 1rem;
	}
}
