$zindex_digitalofferloader: 9995;
$zindex_nav_header: 9996;
$zindex_header: 9997;
$zindex_total-price: 9995;

$zindex_modal: 9999;

$zindex_tooltip: 10001;

$zindex_consent: 99999;

$zindex_debug_bar: 99998;
