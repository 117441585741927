@import "../../../vendor/css/variables-canusa.scss";

.error-message {

	&__h1 {
		margin-bottom: 0.5rem;
	}

	&__h2 {
		color: $color-grey-400;
	}

	&__text-block {
		margin-top: 1rem;

		p + p {
			margin-top: 1rem;
		}
	}
}
