@import "../../../../../vendor/css/variables-canusa";

.item-header {

	&__middle{
		align-items: center;
		height: 100%;
	}

	&__middle-right{
		height: 100%;
		align-items: flex-start;
	}

	//.ie & {
	//	padding-top: 1.5rem;
	//}

	&__date,
	&__duration {
		transition: all 0.3s ease;
		display: flex;
		align-items: center;
		color: $color-grey-800;

		@media only screen and (min-width: $breakpoint_desktop_from) {
			margin-bottom: 0.5rem;
		}

		span {
			line-height: 0;
			margin-left: 4px;
		}

		.icon {
			font-size: 1rem;
		}
	}

	&__note {
		// TODO: replace if note-function is implemented
		margin-left: 1rem;
		font-size: 1.25rem;

		@media only screen and (min-width: $breakpoint_desktop_from) {
			font-size: 1rem;
		}
	}

	&__rating {
		margin-top: 0.25rem;
		line-height: 0;
	}

	&__badge {
		margin: 0;
		.badge {
			transition: all 0.3s ease;
		}
	}

	&__collapsible {

		display: flex;
		justify-content: flex-end;

		.icon {
			font-size: 1rem;
		}

		&--small {
			font-size: 0.75rem;
		}
	}
}
