@import "../../../../../vendor/css/variables-canusa";

.info-header {
	position: relative;
	padding: 1rem;
	margin-top: $header_mobile_height;

	&__location {
		display: flex;
		text-transform: uppercase;
		align-items: center;
		.icon {
			margin-right: 0.25rem;
		}
	}

	&__label {
		text-transform: unset;
		margin-bottom: 0.5rem;
	}

	&__rating {
		.rating {
			> span {
				.icon {
					font-size: 1rem;
				}
			}
		}
	}
}

.static-map + .info-header {
	margin-top: 0;
}
