.icon {
	display: flex;
	fill: currentColor;
	pointer-events: none;

	width: 1.5rem;
	height: 1.5rem;

	&--size-xs {
		width:.875rem;
		height: .875rem;
	}

	&--size-s {
		width:1rem;
		height: 1rem;
	}

	&--size-l {
		width: 1.75rem;
		height: 1.75rem;
	}

	&--size-xl {
		width: 2rem;
		height: 2rem;
	}

	&--rotate{
		animation: rotation 1s linear infinite;
	}
}
