@import "../../../vendor/css/variables-canusa.scss";

.select {
	position: relative;
	display: inline-flex;

	select {
		appearance: none;
		text-align: left;
		background-color: white;
		border: 1px solid $color-gold-400;
		border-radius: 2px;
		font-family: inherit;
		font-weight: inherit;
		cursor: pointer;
		transition: border-color $transition_hover;
		padding-right: 2rem;

		&::-ms-expand {
			display: none; /* hide the default arrow in ie10 and ie11 */
		}

		&:focus {
			outline: none;
		}

		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}

		&:disabled + .select__icon-wrapper {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&__label {
		z-index: 1;
		position: absolute;
		top: 0;
		left: .875rem;
		color: $color-gold-700;
		transform: translateY(-50%);
		font-size: 0.75rem;
		line-height: 1;

		&:after {
			content: '';
			position: absolute;
			top: 49%;
			left: -6px;
			display: block;
			height: 3px; // input border-width + 2px for rendering issues
			width: calc(100% + 12px);
			background-color: white;
			z-index: -1;
		}
	}

	&.readonly {
		label {
			&:after {
				background-color: #fcfcfc;
			}
		}
	}

	&.field-error {
		label {
			color: $color-red-600;
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		right: .715rem;
		font-size: 1rem;
		transform: translateY(-50%);
		line-height: normal;
		transition: color $transition_hover;
		pointer-events: none;
	}

	&--theme-dark {
		.select__label {
			color: $color-grey-400;

			&:after {
				background-color: $color-grey-900;
			}
		}

		select {
			background-color: $color-grey-900;
			border-color: $color-grey-800;
			color: $color-white;
		}

		.select__icon-wrapper {
			.select__icon {
				&:before {
					color: $color-grey-400;
					transition: color $transition_hover;
				}
			}
		}

		&:hover {
			select {
				border-color: $color-gold-700;
			}

			.select__icon-wrapper {
				color: $color-grey-1000;

				.select__icon {
					&:before {
						color: $color-gold-700;
					}
				}
			}
		}
	}

	&--small {
		select {
			padding: 0.625rem 2rem 0.625rem 1rem ;
		}
	}

	&--full-width {
		width: 100%;

		select {
			width: 100%;
		}
	}

}
