@import "../../vendor/css/variables-canusa.scss";

.main-page {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
	background-color: $color-grey-050;

	max-width: $content_max_width;
	width: 100%;
	margin: 0 auto;

	.ie & {
		display: block;
	}

	&__reload-modal {
		bottom: 1rem;
		right: 1rem;
	}

	&__reload-content {
		display: flex;
		flex-direction: column;
	}

	&__reload-headline {
		margin-right: 2rem;
		margin-bottom: .5rem;
	}

	&__reload-link {
		margin-right: 0;
		margin-left: auto;
	}

	&__content {
		background-color: $color-grey-050;
		padding: 1rem 0 0;
		max-width: $max_width;
		margin: auto;
		width: 100%;
		display: flex;
		flex-flow: column nowrap;

		> div {
			width: 100%;
		}

		@media screen and (min-width: $breakpoint_tablet_from) {
			padding: 1.5rem;
		}

		@media screen and (min-width: $breakpoint_desktop_from) {
			padding: 2rem;
		}
	}

	&__contact-box {
		margin-bottom: 1rem;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			display: none;
		}
	}

	&__countdown {
		margin: 4rem auto 1rem auto;
		color: $color-grey-800;

		@media screen and (min-width: $breakpoint_tablet_from), print {
			margin: 6rem auto 2.5rem auto;
		}
	}

	&__header-wrapper {

		z-index: $zindex_header;
		display: flex;
		justify-content: center;
		position: sticky;
		top: 0;

		.header {
			z-index: 10;
		}

		.debug-bar {
			z-index: 11;
		}
	}

	&__header-notifications {
		position: absolute;
		top: 100%;
		width: 100%;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		&__content {
			background-color: transparent;
			padding: 2rem 0 0;
			height: auto;
		}

		&__navigation, &__header-notifications {
			display: none !important;
		}

		&__header-wrapper {
			position: relative;
		}


		.consent {
			display: none;
		}
	}
}
