@import "../../../vendor/css/variables-canusa.scss";

$block: '.checkout-price-table';

#{$block} {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	padding: 1rem 1.5rem;
	margin-top: 1rem;

	&__item {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0;
		border-bottom: 1px solid $color-grey-200;

		&:last-child{
			border-bottom: none;
		}
	}

	&__icon {
		width: 2.5rem;
		color: $color-gold-700;
	}

	&__label {
		flex-grow: 1;
		text-align: left;
		flex-basis: 50%;
	}

	&__price {
		text-align: right;
		flex-basis: 25%;
		justify-self: flex-end;
		align-self: flex-end;
	}

	&__line {
		height: 1px;
		background-color: $color-grey-200;
		width: 100%;
		margin: 0.25rem 0 0.5rem;
	}

	&__total-price-wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	  	font-size: 1rem;
	}
}
