@import "../../../vendor/css/variables-canusa.scss";
@import "../../../vendor/css/zindex-canusa.scss";

.consent-checkbox {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	margin-bottom: 1.5rem;

	@media screen and (min-width: $breakpoint_tablet_from){
		margin-bottom: 0;
		margin-right: 40px;
	}

	p {
		line-height: 1.5;
	}

	&__wrapper {
		display: flex;
		flex-flow: row;
		align-items: center;
		margin-bottom: 1rem;

		@media screen and (min-width: $breakpoint_tablet_from){
			flex-flow: column;
			align-items: unset;
			margin-bottom: 0;
		}
	}

	&__checkbox {
		margin-right: 0.5rem;
		@media screen and (min-width: $breakpoint_tablet_from){
			margin-bottom: 1rem;
			margin-right: 0;
		}

		> label {
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__label {
		@media screen and (min-width: $breakpoint_tablet_from){
			margin-bottom: 1rem;
		}
	}

}

