@import "../../../../vendor/css/variables-canusa.scss";

.header {
	&__interaction {
		display: flex;
		align-items: center;
	}

	&__offer {
		margin-right: 0.75rem;
		padding-right: 0.75rem;
		text-align: right;
		display: flex;

		>* + * {
			margin-left: 1.5rem;
		}
	}

	.button + .button {
		margin-left: 1.5rem;
	}

	@media print {
		&__offer {
			margin-right: 0;
			border: none;
			display: block;
		}
	}
}
