@import "../../../vendor/css/variables-canusa.scss";

.offer-content {
	display: flex;
	flex-flow: row nowrap;
	margin: auto;

	&__intro-badge {
		position: relative;

		@media only screen and (min-width: $breakpoint_desktop_from) {
			position: absolute;
			right: 2rem;
			top: 2rem;
		}
	}

	&__offer--display-inline {
		> * {
			display: inline;
		}
	}

	&__main {
		width: 100%;
		@media only screen and (min-width: $breakpoint_desktop_from) {
			width: 62%;
		}
	}

	&__side {
		display: none;
		position: sticky;

		.ie & {
			top: 0;
			height: 100vh;
		}

		@media only screen and (min-width: $breakpoint_desktop_from) {
			display: flex;
			padding-left: 1rem;
			width: 38%;
			top: ($header_height+$nav_header_height);
			height: calc(100vh - #{$header_height+$nav_header_height});
		}
	}

	&__current-offer-btn {
		margin-top: 1.5rem;
	}

	&__contact-box {
		@media screen and (min-width: $breakpoint_tablet_from), print {
			display: none;
		}
	}

	&__intro {
		margin-bottom: 1rem;
	}

	&__intro-headline {
		padding-right: 0;
		margin-top: 1rem;

		@media only screen and (min-width: 1100px) {
			margin-top: 0;
		}

		@media only screen and (min-width: $breakpoint_desktop_from) {
			padding-right: 15%;
		}
	}

	&__intro-text {
		margin-top: 1rem;
		@media only screen and (min-width: $breakpoint_desktop_from) {
			margin-top: 2rem;
		}
	}

	&__intro-headline + &__intro-text {
		margin-top: 1rem;
	}

	&__intro-text + &__print-trigger {
		margin-top: 1.5rem;

	}

	&__route-btn {
		margin-top:0;
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		&__route-btn,
		&__print-trigger {
			display: none !important;
		}
		&__summary {
			page-break-inside: avoid;
		}
	}
}
