@import "../../../vendor/css/variables-canusa.scss";
@import "../../../vendor/css/zindex-canusa.scss";

$framesNum: 7;
$animationDuration: 0.35s;
$frameWidth: 80px;
$frameHeight: 80px;
$b: $color-grey-900;

$preloader: '.preloader';

#{$preloader} {
	position: relative;
	z-index: $zindex_digitalofferloader;
	transition: opacity .3s ease, width 0s linear 0.3s, height 0s linear 0.3s;
	opacity: 0;
	height: 0;
	width: 0;

	&--show {
		opacity: 1;
		transition: opacity .3s ease 0.1s;
	}

	&--text {
		margin-bottom: 1rem;

		&#{$preloader}--show {
			height: auto;
			width: auto;
		}
	}

	&--line {

		display: flex;
		align-items: center;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
			animation: pulsate $preloader_pulsing_time $transition_ease infinite;
			color: $color-grey-400;
		}

		&#{$preloader}--show {
			height: auto;
			width: auto;
		}
	}

	&--block {
		width: 100%;
		animation: pulsate $preloader_pulsing_time $transition_ease infinite;
		background: $color-grey-400;
		color: $color-white;
	}

	&--full-screen {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-white;

		&#{$preloader}--show {
			width: 100vw;
			height: 100vh;
			left: 0;
		}
	}

	&__text {
		animation: pulsate $preloader_pulsing_time $transition_ease infinite;
		background: $color-grey-100;
		color: rgba(0, 0, 0, 0);
		margin-bottom: 0.5rem;
		display: inline;
	}

	&__line {
		animation: pulsate $preloader_pulsing_time $transition_ease infinite;
		background: $color-grey-100;
		color: rgba(0, 0, 0, 0);
		display: inline;
	}

	/* PRELOADER FOR CONTACTBOX IN HEADER*/
	&--contactbox {
		height: auto;
		width: auto;

		margin-top: 2.5rem;
		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-top: 0;
		}

	}

	&__contactbox {
		display: flex;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			.main-page__content & {
				display: none;
			}
		}

		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;

		background-color: $color-white;
		border-radius: $size_border_radius;
		box-shadow: $slight_shadow;

		padding: 1rem;
		margin-bottom: 1rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			flex-flow: row nowrap;

			background-color: transparent;
			border-radius: 0;
			box-shadow: none;

			padding: 0;
			margin-bottom: 0;
		}
	}

	&__contactbox-image {
		border-radius: 50%;
		border: 2px solid $color-white;
		width: 56px;
		height: 56px;
		position: relative;
		overflow: hidden;

		margin-top: -2.5rem;

		@media only screen and (min-width: $breakpoint_tablet_from) and (max-width: $breakpoint_desktop_to) {
			display: none;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-top: 0;
		}

		&:before {
			content: '';
			background: $color-grey-100;
			animation: pulsate $preloader_pulsing_time $transition_ease infinite;
			position: absolute;

			width: 100%;
			height: 100%;
		}
	}

	&__contactbox-text {

		margin: 0 auto;
		text-align: center;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-left: 1rem;
			text-align: left;
		}

		> div {
			display: inline;
			animation: pulsate $preloader_pulsing_time $transition_ease infinite;
			background: $color-grey-100;
			color: rgba(0, 0, 0, 0);
		}
	}

	&__canusa {
		display: block;
		width: $frameWidth;
		height: $frameHeight;
		animation: canusa-loader-steps $animationDuration steps($framesNum) infinite;
		background-image: url('/assets/loader/canusa_loader_80x80_' + $framesNum + '.png');
	}

	&--logo {

		padding: 6rem 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.preloader__canusa {
			display: block;
			width: $frameWidth;
			height: $frameHeight;
			animation: canusa-loader-steps $animationDuration steps($framesNum) infinite;
			background-image: url('/assets/loader/canusa_loader_80x80_' + $framesNum + '.png');
		}
	}
}

@keyframes canusa-loader-steps {
	from {
		background-position-x: 0;
	}
	to {
		background-position-x: $frameWidth * $framesNum * -1;
	}
}
