@import "../../../vendor/css/variables-canusa.scss";

.no-offer-page {

	&__box {
		margin-bottom: 3rem;
	}

	&__h1 {
		margin-bottom: 0.5rem;
	}

	&__h2 {
		color: $color-grey-400;
	}

	&__text-block {
		margin: 2rem 0 1rem;
	}

	&__column-wrapper{
		display: flex;
		flex-flow: column nowrap;

		@media only screen and (min-width: $breakpoint_desktop_from){
			flex-flow: row nowrap;
		}
	}

	&__column{
		display: inline;
		width: auto;
		min-width: 50%;
	}
}
