@import "../../../vendor/css/variables-canusa.scss";

.offer-info {

	margin-top: 2rem;
	border-top: 1px solid $color-grey-100;
	border-bottom: 1px solid $color-grey-100;
	padding: 1rem 0;

	display: flex;
	flex-flow: column nowrap;
	justify-content: center;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	}

	&__offer-number {
		> div {
			text-align: center;
			@media only screen and (min-width: $breakpoint_tablet_from) {
				text-align: left;
			}

			> div + div {
				margin-top: 0.25rem;
			}
		}
	}

	&__offer-print-select {
		margin-top: 1.5rem;
		display: flex;
		flex-flow: column nowrap;

		> .link + .link {
			margin-top: 0.5rem;
		}


		@media only screen and (min-width: $breakpoint_tablet_from) {
			margin-top: 0;
		}

		@media print {
			display: none;
		}
	}
}
