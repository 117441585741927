@import "../../../vendor/css/variables-canusa.scss";

.rating {
	display: inline-flex;
	justify-content: center;
	position: relative;
	white-space: nowrap;
	vertical-align: top;
	font-size: .7em;
	line-height: normal;

	&__star {
		position: relative;

		svg {
			width: 1rem;
			height: 1rem;
		}
	}

	&__star-wrapper {
		position: relative;
	}

	&__star--background-filled {
		.rating__star {
			position: absolute;
			top: 0;
			left: 0;
		}

		.rating__star--background {
			position: relative;
			color: $color-grey-100
		}
	}

	&.rating__star--color-signal {
		.rating__star {
			color: $color-yellow-700;
		}
	}

	&--small {
		font-size: 0.75rem;

		.rating__star {
			padding: 3px 3px 3px 0;

			svg {
				width: .5rem;
				height: .5rem;
			}
		}
	}

}
