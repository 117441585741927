@import "../../../../vendor/css/variables-canusa.scss";

.gallery-slider {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    img {
      max-width: 84vw;
      max-height: 84vh;
      width: auto;
      margin: auto;
    }
  }

  &__btn {
    background-color: $color-grey-800;
    color: $color-white;
    padding: 1rem 0.875rem;
    font-size: 2rem;
    width: unset;
    height: unset;

    &:hover {
      background-color: $color-grey-1000;
    }

    // Expand click area for slider navigation buttons
    &:after {
      content: '';
      position: absolute;
      top: -42vh;
      bottom: -42vh;
      left: -50vw;
      right: -50vw;
    }
  }
}
