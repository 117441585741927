@import "../../../../../vendor/css/variables-canusa";

.item-header-optionals {

	&__price {
		@media only screen and (min-width: $breakpoint_desktop_from) {
			margin-right: 1rem;
			min-width: 3.75rem;
			text-align: right;
		}
		align-self: center;
	}

	&__badge-list {
		margin-left: 1rem;
		margin-top: -10px;
		height: 20px;
	}

	&__badge {
		margin: 0;
	}

	&__label-wrapper {
		margin-top: 0.5rem;
		display: flex;
		align-items: center;
	}

	.item-header__middle-right {
		align-items: flex-start;
	}
}

