@import "../../../vendor/css/variables-canusa.scss";

$backgroundImageDot: '/assets/img/dot-for-headline.svg';

$block: '.footer';

#{$block} {
  background-color: $color-grey-100;
  color: $color-grey-400;
  margin-top: 3rem;

  &--no-margin {
    margin-top: 0;
  }

  &__link {
    color: $color-grey-500;
    line-height: 2;
    margin-bottom: 0.5rem;
    @media only screen and (min-width: 1040px) {
      margin-bottom: 0;

    }

    &:hover {
      color: $color-grey-600;
    }
  }

  &__svg-map {
    fill: $color-gold-300;
  }

  &__svg-map-text {
    font-family: $fontFamily;
    fill: $color-grey-600;
    font-size: 9px;
    letter-spacing: 0.35px;
  }

  &__svg-map-circle {
    fill: $color-white;
  }

  &__company-link {
    margin-top: 0.5rem;
  }

  &-lower-nav {
    background-color: $color-gold-200;
    margin-top: 0;
    font-size: 1rem;

    &__logo-wrapper {
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: center;

      &:before {
        position: absolute;
        top: -1px;
        content: "";
        flex-grow: 1;
        flex-shrink: 1;
        width: 100%;
        height: 2px;
        background-image: url(#{$backgroundImageDot});
        background-repeat: repeat-x;
        background-size: 4px 4px;
      }
    }

    &__logo {
      background-color: $color-gold-200;
      border-radius: 100%;

      position: absolute;
      top: -1.75rem;
      width: 3.5rem;
      height: 3.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1.9rem;
        height: 1.9rem;
      }
    }

    &__wrapper {
      width: 100%;
      max-width: $content-max-width;
      margin: 0 auto;

      padding: 3rem 2rem 2rem;

      display: flex;
      justify-content: space-between;

      flex-flow: column nowrap;
      align-items: center;
      @media only screen and (min-width: 1040px) {
        flex-flow: row nowrap;
      }

      > div,
      > ul {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
    }

    &__claim {
      display: flex;
      flex-flow: column nowrap;
      max-width: $column-6;
      text-align: center;

      margin-bottom: 2rem;

      order: 1;
      @media only screen and (min-width: 1040px) {
        margin-bottom: 0;
        order: 2;
      }

      &-headline {
        font-size: 1.5rem;
        width: 100%;
        color: $color-grey-600;

        &.ts-handwriting {
          line-height: 2rem;
        }
      }


      &-copy {
        color: $color-grey-500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 0 1rem;
        @media only screen and (min-width: 1040px) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }

    &__meta {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      order: 3;
      margin-top: 1rem;
      margin-bottom: 0;

      @media only screen and (min-width: 1040px) {
        flex-flow: row wrap;
        justify-content: flex-end;
        margin-top: 0rem;
        margin-bottom: 1rem;
        order: 3;
      }
    }


    &__meta-chunk {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      flex-flow: column nowrap;
      text-align: center;
      line-height: 3;


      @media only screen and (min-width: 1040px) {
        flex-flow: row wrap;
        text-align: left;

        margin-left: 0;

        &:last-of-type {
          margin-left: 2rem;
        }

        > a {
          margin-left: 2rem;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }

  //--------------------------------------------------- PRINT ONLY ---------------------------------------------------

  @media print {
    display: none !important;
  }
}

