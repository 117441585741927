@import "../../../../../vendor/css/variables-canusa";

.item-header-documents {

	.item-header {

		&__middle{
			align-items: center;
			height: 100%;
		}

		&__badge {
			.badge {
				text-transform: uppercase;
			}
		}

		&__link {
			margin-left: 1rem;

			.link {
				display: flex;
				pointer-events: auto;

				.icon {
					font-size: 1rem;
				}
			}
		}
	}
}
