@import "../../../vendor/css/variables-canusa.scss";

.invoice-common {
	position: relative;
	background-color: $color-white;

	&__state {
		position: relative;
		margin-left: auto;
		margin-bottom: 1rem;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			position: absolute;
			top: 2rem;
			right: 2rem;
			margin-bottom: 0;
		}
	}
	&__headline + &__text {
		margin-top: 1rem;
	}

	&__interaction {

		display: flex;
		flex-flow: column nowrap;
		margin-top: 1rem;

		> div + div {
			margin-top: 2rem;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			align-items: center;
			flex-flow: row nowrap;
			justify-content: space-between;
			> div + div {
				margin-top: 0;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;

		& + & {
			margin-top: 1rem;
		}
	}

	&__link-badge {
		margin-left: 1rem;
	}

	.link-item-list {
		margin-top: 1rem;

		a + button {
			margin-top: 1rem;
		}

		@media only screen and (min-width: $breakpoint_tablet_from) {
			a + button {
				margin-top: 0;
			}
			justify-content: space-between;
			align-items: center;
			flex-flow: row nowrap;
		}
	}
}
