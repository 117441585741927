@import "../../../vendor/css/variables-canusa.scss";

$alternativeTeaser: '.alternative-teaser';

#{$alternativeTeaser} {

	&--active {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			pointer-events: none;
		}
	}

	&__item {
		border: 1px solid $color-grey-100;
		background-color: $color-white;
		box-shadow: $slight_shadow;
		cursor: pointer;

		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
	}

	&--default {

		&#{$alternativeTeaser}--active {
			&:after {
				border: 1px solid $color-yellow-700;
				width: calc(100% - 0.5px);
				height: calc(100% - 0.5px);
			}
		}

		#{$alternativeTeaser}__item {
			width: 327px;
			height: 52px;
		}

		#{$alternativeTeaser}__image-wrapper {

			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			border-right: 1px solid $color-grey-100;
			width: 82px;
			height: 100%;

		}

		#{$alternativeTeaser}__label-wrapper {

			display: flex;
			flex-shrink: 3;
			flex-flow: column nowrap;
			justify-content: center;
			padding: 0 0.5rem 0 1rem;
			height: 100%;
			overflow: hidden;

		}

		#{$alternativeTeaser}__label {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			color: $color-grey-900;

		}

		#{$alternativeTeaser}__rating {
			height: 14px;

			.rating__star {
				color: $color-yellow-700;
			}
		}

		#{$alternativeTeaser}__selector-wrapper {
			display: flex;
			flex-flow: row-reverse;
			align-items: center;
			padding: 0 0.5rem 0 1.5rem;
			height: 100%;
		}

		#{$alternativeTeaser}__price {
			color: $color-yellow-700;
			white-space: nowrap;
			margin-right: 0.5rem;

		}

		#{$alternativeTeaser}__radiobutton {
			display: block;
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	&--expanded {

		&#{$alternativeTeaser}--active {
			&:after {
				border: 2px solid $color-yellow-700;
				width: calc(100% - 1px);
				height: calc(100% - 1px);
			}
		}

		#{$alternativeTeaser}__item {
			width: 327px;
			height: 184px;
		}

		#{$alternativeTeaser}__image-wrapper {
			position: absolute;
			width: 100%;
			height: auto;

			&:after {
				content: '';
				background-color: $color-grey-1000;
				opacity: 0.4;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		#{$alternativeTeaser}__label-wrapper {
			position: absolute;
			top: 1rem;
			left: 1rem;
			padding-right: 3rem;
		}

		#{$alternativeTeaser}__rating-star--color-signal {

			font-size: 1rem;
			margin-bottom: 6px;

			.rating__star {
				color: $color-yellow-700;
			}

			.rating__star--background {
				color: $color-grey-400;
			}
		}

		#{$alternativeTeaser}__label {
			color: $color-white;
			font-size: 1rem;

		}

		#{$alternativeTeaser}__price {
			position: absolute;
			bottom: 0.5rem;
			right: 0.5rem;
			color: $color-white;
			font-size: 1.25rem;
		}

		#{$alternativeTeaser}__radiobutton {

			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			width: 2rem;
			height: 2rem;

			label {
				.icon {
					font-size: 2rem;
				}
			}
		}

	}

	&--minimal {
		#{$alternativeTeaser}__item {
			width: 140px;
			height: 80px;
			border: 1px solid $color-grey-100;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: space-between;
			background-color: $color-white;
			overflow: hidden;
			margin-right: 0.5rem;

			pointer-events: none;

		}

		#{$alternativeTeaser}__image-wrapper {
			position: absolute;
			width: 100%;
			height: auto;
		}

		#{$alternativeTeaser}__radiobutton {
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			width: 1.5rem;
			height: 1.5rem;
		}
	}
}


