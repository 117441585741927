@import "../../../vendor/css/variables-canusa.scss";

.tab-navigation {
	display: flex;
	align-items: center;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		overflow-x: unset;

		&::-webkit-scrollbar {
			display: initial;
		}
	}
}
