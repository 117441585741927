@import "../../../vendor/css/variables-canusa.scss";

.pdf-link {
	&.has-error{
		.icon{
			color: $color-red-700;
		}
	}

}
