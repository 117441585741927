@import "../../../vendor/css/variables-canusa.scss";

$block: '.socialmedia-block';

#{$block} {

	display: flex;
	width: auto;
	order: 2;
	margin-bottom: 0;

	&__icon {
		transition: opacity $transition_time ease;
	}

	&__link {
		display: flex;
		justify-content: center;
		align-items: center;

		margin-right: 1rem;
		margin-bottom: 1rem;

		cursor: pointer;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			color: inherit;

			#{$block}__image {

				> img {
					transform: scale(1.05);
				}
			}
		}
	}

	&--icon {
		#{$block}__link {
			&:hover {
				color: inherit;

				#{$block}__icon {
					opacity: 0.8;
				}
			}
		}
	}


	&--image {
		#{$block}__image {
			overflow: hidden;

			> img {
				transition: transform $transition_time ease;
			}
		}

		#{$block}__icon {
			position: absolute;

			svg {
				height: 50px;
				width: 50px;
			}
		}
	}

	&--footer-nav {
		#{$block}__link {
			margin-top: 2rem;
			margin-right: 2rem;
			margin-bottom: 2rem;

			&:last-child {
				margin-right: 0;
			}

			@media only screen and (min-width: 1040px) {
				margin-top: 0;
				margin-bottom: 1rem;
				margin-right: 4rem;
			}
		}

		#{$block}__icon {
			> svg {
				width: 1.8rem;
				height: 1.8rem;
			}
		}
	}

	&--dark {
		color: $color-gold-700;
	}

	&--light {
		color: $color-white;
	}
}
