@import "../../../vendor/css/variables-canusa.scss";

.gallery {

	&__thumb {
		margin-bottom: 0.5rem;
		overflow: hidden;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__modal {
		.modal__layer {
			padding: 0;
		}
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
		display: grid;
		grid-gap: .625rem;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: repeat(12, 1fr);

		&__thumb {
			margin-bottom: 0;
			cursor: pointer;

			&:hover {
				.gallery__thumb-img {
					transform: scale(1.05);
				}
			}
		}

		&__thumb-img {
			transition: transform 0.8s ease;
		}

		&--imgs-1 {
			.gallery__thumb {
				&:nth-child(1) {
					grid-column: auto / span 12;
					grid-row: auto / span 12;
				}
			}
		}

		&--imgs-2,
		&--imgs-4 {
			.gallery__thumb {
				grid-column: auto / span 6;
				grid-row: auto / span 12;
			}
		}

		&--imgs-3 {
			.gallery__thumb {
				&:nth-child(1) {
					grid-column: 1 / span 8;
					grid-row: 1 / span 12;
				}

				&:nth-child(2) {
					grid-column: 9 / span 4;
					grid-row: 1 / span 6;
				}

				&:nth-child(3) {
					grid-column: 9 / span 4;
					grid-row: 7 / span 6;
				}
			}
		}

		&--imgs-5 {
			grid-template-rows: repeat(7, 1fr);

			.gallery__thumb {
				&:nth-child(1) {
					grid-column: 1 / span 6;
					grid-row: 1 / span 3;
				}

				&:nth-child(2) {
					grid-column: 7 / span 6;
					grid-row: 1 / span 3;
				}

				&:nth-child(3) {
					grid-column: 1 / span 8;
					grid-row: 4 / span 4;
				}

				&:nth-child(4) {
					grid-column: 9 / span 4;
					grid-row: 4 / span 2;
				}

				&:nth-child(5) {
					grid-column: 9 / span 4;
					grid-row: 6 / span 2;
				}
			}
		}

		&--imgs-6 {
			.gallery__thumb {
				&:nth-child(1) {
					grid-column: 1 / span 8;
					grid-row: 1 / span 6;
				}

				&:nth-child(2) {
					grid-column: 9 / span 4;
					grid-row: 1 / span 3;
				}

				&:nth-child(3) {
					grid-column: 9 / span 4;
					grid-row: 4 / span 3;
				}

				&:nth-child(4) {
					grid-column: 1 / span 4;
					grid-row: 7 / span 3;
				}

				&:nth-child(5) {
					grid-column: 1 / span 4;
					grid-row: 10 / span 3;
				}

				&:nth-child(6) {
					grid-column: 5 / span 8;
					grid-row: 7 / span 6;
				}
			}
		}

		&--imgs-7 {
			grid-template-rows: repeat(8, 1fr);

			.gallery__thumb {
				&:nth-child(1) {
					grid-column: 1 / span 6;
					grid-row: 1 / span 3;
				}

				&:nth-child(2) {
					grid-column: 7 / span 6;
					grid-row: 1 / span 3;
				}

				&:nth-child(3) {
					grid-column: 1 / span 4;
					grid-row: 4 / span 2;
				}

				&:nth-child(4) {
					grid-column: 5 / span 4;
					grid-row: 4 / span 2;
				}

				&:nth-child(5) {
					grid-column: 9 / span 4;
					grid-row: 4 / span 2;
				}

				&:nth-child(6) {
					grid-column: 1 / span 6;
					grid-row: 6 / span 3;
				}

				&:nth-child(7) {
					grid-column: 7 / span 6;
					grid-row: 6 / span 3;
				}
			}
		}

		&--imgs-8 {
			grid-template-rows: repeat(11, 1fr);

			.gallery__thumb {
				&:nth-child(1) {
					grid-column: 1 / span 8;
					grid-row: 1 / span 4;
				}

				&:nth-child(2) {
					grid-column: 9 / span 4;
					grid-row: 1 / span 2;
				}

				&:nth-child(3) {
					grid-column: 9 / span 4;
					grid-row: 3 / span 2;
				}

				&:nth-child(4) {
					grid-column: 1 / span 6;
					grid-row: 5 / span 3;
				}

				&:nth-child(5) {
					grid-column: 7 / span 6;
					grid-row: 5 / span 3;
				}

				&:nth-child(6) {
					grid-column: 1 / span 4;
					grid-row: 8 / span 2;
				}

				&:nth-child(7) {
					grid-column: 1 / span 4;
					grid-row: 10 / span 2;
				}

				&:nth-child(8) {
					grid-column: 5 / span 8;
					grid-row: 8 / span 4;
				}
			}
		}

		&--imgs-9 {
			.gallery__thumb {
				grid-column: auto / span 4;
				grid-row: auto / span 4;
			}
		}
	}
}

.print-image {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 1rem;

	&__image {
		margin: 0 1rem 1rem 0;
		opacity: 1;
		display: inline-flex;
		width: fit-content;
		height: 100px;

		&.img {
			opacity: 1;
			display: block;
			transition: none;
			animation: none;

			img {
				max-height: 100px;
				width: auto;
			}
		}
	}
}
