@import "../../../vendor/css/variables-canusa.scss";

.checkbox {

	display: block;
	position: relative;

	input {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	label {
		font-size: 1.5rem;
		line-height: inherit;
		transition: color .3s linear;
	}

	input:checked + label {
		color: $color-gold-700;
	}

	input:disabled + label {
		opacity: .5;
		pointer-events: none;
	}

	&.error {
		label {
			color: $color-red-600;
		}
	}

	input:not(:checked) + label {

		> :first-child {
			opacity: 0;
		}

		> :last-child {
			opacity: 1;
		}
	}

	input:checked + label {

		> :first-child {
			opacity: 1;
		}

		> :last-child {
			opacity: 0;
			transition: opacity .3s ease;
		}
	}

	+ label {
		cursor: pointer
	}

	label {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		height: 100%;
		width: 100%;

		//checked icon
		:first-child {
			position: absolute;
			top: 0;
			left: 0;
			transition: opacity .3s ease;
		}
	}
}
