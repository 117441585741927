@import "../../vendor/css/variables-canusa.scss";
@import "../../vendor/css/zindex-canusa.scss";

.debug-bar {
	z-index: $zindex_debug_bar;
	opacity: 1;
	min-height: 1rem;
	.icon {
		width: 0.5rem;
		height: 0.5rem;
	}

	&__collapsible {
		position: relative;
		top: 0;
		margin: 0;
		transition: top 0.3s ease;

		.head {
			font-size: 0.5rem;


		}

		.inner-content {
			display: flex;
			flex-flow: column;

			* + * {
				margin-top: 1rem;
			}

			@media screen and (min-width: 768px) {

				flex-flow: row;
				* + * {
					margin-top: unset;
				}

				justify-content: space-around;
			}
		}
	}

	&--hide {
		.debug-bar__collapsible {
			top: -1rem;
		}
	}

	position: absolute;
	top: 0;
	width: 100%;

	&__close-button {
		position: absolute;
		top: 0.1rem;
		right: 0.3rem;
		color: $color-gold-700;
	}

	@media print {
		display: none;
	}
}
