@import "zindex-canusa.scss";

//COLORS

$color-white: #ffffff;
$color-black: #000000;

$color-grey-050: #f8f7f7;
$color-grey-100: #f1f0ef;
$color-grey-200: #dddddd;
$color-grey-300: #bfbfbf;
$color-grey-400: #999999;
$color-grey-500: #8d8d8d;
$color-grey-600: #797979;
$color-grey-800: #565656;
$color-grey-900: #494949;
$color-grey-1000: #262626;

$color-red-error: #ff3f33;
$color-red-600: #f00000;
$color-red-700: #cd1316;
$color-red-800: #b51918;

$color-gold-050: #f8f6f1;
$color-gold-200: #e8e7e1;
$color-gold-250: #ece7dc; // f.k.a. $color_light_gold: #ece7dc
$color-gold-300: #dbd8d1;
$color-gold-400: #c6c3b8;
$color-gold-600: #b3ac9e;
$color-gold-700: #928978;
$color-gold-800: #716b5f;

$color-yellow-600: #febc34;
$color-yellow-700: #f7a600;


// sizes
$size_border_radius: 2px;
$header_mobile_height: 4.875rem;
$header_height: 6rem;
$modal_header_height: $header_mobile_height;
$nav_header_height: 3rem;
$max_width: 1920px;
$slight_shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
$medium_shadow: 0 1px 8px 0 rgba(86, 86, 86, 0.2);

$navigation_shadow: 0 0 16px 4px rgba(0, 0, 0, 0.12), 0 -4px 8px -4px rgba(0, 0, 0, 0.04);
$fontFamily: "Roboto", Helvetica, "Helvetica Neue", Arial, sans-serif;


// transitions
$transition_time: 0.5s;
$preloader_pulsing_time: 4.5s;
$transition_hover: 0.35s ease;
$transition_in_time: 0.7s;
$transition_ease: cubic-bezier(0.4, 0, 0.2, 1);

// breakpoints
$breakpoint_large_desktop_from: 1100px;
$breakpoint_large_desktop_to: 1099px;

$breakpoint_large_tablet_from: 991px;

$breakpoint_desktop_from: 1024px;
$breakpoint_desktop_to: 1023px;
$breakpoint_tablet_from: 768px;
$breakpoint_tablet_to: 767px;


$content_max_width: 1920px;

// COLUMNS
$columnWidth: 65px;
$gutterWidth: 16px;

$columnGutterWidth: $columnWidth + $gutterWidth;

$column-1: $columnGutterWidth - $gutterWidth;
$column-2: $columnGutterWidth * 2 - $gutterWidth;
$column-3: $columnGutterWidth * 3 - $gutterWidth;
$column-4: $columnGutterWidth * 4 - $gutterWidth;
$column-6: $columnGutterWidth * 6 - $gutterWidth;
$column-5: $columnGutterWidth * 5 - $gutterWidth;
$column-8: $columnGutterWidth * 8 - $gutterWidth;
$column-10: $columnGutterWidth * 10 - $gutterWidth;
$column-12: $columnGutterWidth * 12 - $gutterWidth;
$column-14: $columnGutterWidth * 14 - $gutterWidth;
$column-16: $columnGutterWidth * 16 - $gutterWidth;

$content-width: 1200px;
$content-max-width: 1920px;

@mixin h1 {
	font-size: 2rem;
	letter-spacing: 0.5px;
	line-height: 1;
	font-weight: 500;
}

@mixin h1-handwriting {
	font-size: 2.5rem;
	line-height: 1.1;
	font-weight: normal;
	letter-spacing: normal;
}

@mixin h2 {
	font-size: 1.5rem;
	letter-spacing: 0.5px;
	line-height: 1.17;
	font-weight: 700;
}

@mixin h2-handwriting {
	font-size: 1.75rem;
	line-height: 1.14;
	font-weight: normal;
	letter-spacing: normal;
}

@mixin h3 {
	font-size: 1.25rem;
	line-height: 1.2;
	font-weight: 700;
	letter-spacing: 0.5px;
}

@mixin h3-handwriting {
	font-size: 1.5rem;
	line-height: 1.17;
	font-weight: normal;
	letter-spacing: normal;
}

@mixin h4 {
	font-size: 1rem;
	line-height: 1.25;
	font-weight: 700;
}

@mixin h5 {
	font-size: 0.875rem;
	line-height: 1.14;
	letter-spacing: 0.5px;
	font-weight: 500;
}

@mixin copy-medium {
	font-size: 0.875rem;
	line-height: 1.43;
}

@mixin copy-small {
	font-size: 0.75rem;
	line-height: 1.33;
}
