@import "../../../vendor/css/variables-canusa.scss";

$block: '.participants-form';
#{$block} {

	&__content {
		margin-top: 4rem;
		display: flex;
		flex-flow: row wrap;
		margin-bottom: -1rem;
		width: 100%;
	}

	&__mandatory-text {
		margin-top: 0.5rem;
		color: $color-grey-400
	}

	&__participant {
		display: flex;
		max-width: 100%;
		width: 100%;
		margin-bottom: 3.5rem;
		align-items: stretch;
		@media screen and (min-width: $breakpoint_tablet_from), print {
			max-width: 50%;
		}
	}

	&__renter {
		float: left;
		width: 100%;
		max-width: 100%;
		margin-bottom: 1rem;
	}

	&__participant:nth-child(even) {
		@media screen and (min-width: $breakpoint_tablet_from), print {
			padding-left: 2rem;
		}
	}

	&__participant-content {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		>.h3 {
			margin-bottom: 0.75rem;
		}
		/*@media print {
			padding: 0.75rem 1.5rem 1.5rem;
		}*/
	}

	&__wrapper {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		margin-top: 1rem;

		@media screen and (min-width: $breakpoint_tablet_from), print {
			flex-flow: row nowrap;
		}
	}

	&__item-wrapper {
		display: flex;

		.tooltip-wrapper + .select,
		.input + .input {
			margin-top: 0;
			margin-left: 1rem;
		}
	}

	&--thirdwidth {
		max-width: 100%;
		width: 100%;

		+ #{$block}--thirdwidth {
			margin-top: 1rem;
		}

		@media screen and (min-width: $breakpoint_tablet_from), print {
			margin-top: 0;
			max-width: calc(33% - 0.5rem);

			+ #{$block}--thirdwidth {
				margin-top: 0rem;
			}
		}
	}

	&--halfwidth {
		max-width: 100%;
		width: 100%;

		+ #{$block}--halfwidth {
			margin-top: 1rem;
		}

		@media screen and (min-width: $breakpoint_tablet_from), print {
			+ #{$block}--halfwidth {
				margin-top: 0;
			}
			max-width: calc(50% - 0.5rem);
		}
	}

	&--fullwidth {
		width: 100%;
	}

	&__buttons {
		padding-top: 2rem;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
	}
}
