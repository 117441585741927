@import "../../../../vendor/css/variables-canusa.scss";

.documents-common {

	@media only screen and (min-width: $breakpoint_tablet_from) {
		margin-bottom: 2rem;
	}

	&__text_wrapper {
		max-width: $breakpoint_desktop_to;
	}

	&__headline + &__text {
		margin-top: 1rem;
	}

	&__link-list {
		margin-top: 1.5rem;
	}

	&__link-list + .preloader--line {
		margin-top: .5rem;
	}

	&__link {
		display: flex;
		align-items: center;

		& + & {
			margin-top: 1rem;
		}

		&-badge{
			margin-bottom: 0;
		}

	}

	&__disclaimer {
		margin-top: 1.5rem;
	}
	&__disclaimer :first-child {
		margin-right: .5rem;
	}

	&__error-wrapper{
		width: 100%;
		display: flex;
		flex-flow: column-reverse nowrap;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;

		@media only screen and (min-width: $breakpoint_desktop_from){

			flex-flow: row nowrap;
			align-items: center;
			margin-bottom: 0;
		}
	}

	&__reload-button{
		margin-right: 1rem;
		margin-top: 1rem;
		@media only screen and (min-width: $breakpoint_desktop_from){
			margin-top: 0;
		}
	}


	&__link-badge {
		margin-left: 1rem;
		margin-bottom: 0;
	}
}

