@import "../../../vendor/css/variables-canusa.scss";

.offer-print-select {
	display: flex;
	flex-flow: column nowrap;

	&__headline {
		margin-bottom: 1rem;
	}

	&__divider {
		margin: 1.5rem 0;
	}

	&__submit {
		margin-top: 0.5rem;
	}

	&__checkbox {
		display: flex;
		align-items: center;

		> label {
			margin-left: 0.5rem;
		}
	}

	&__offernumber {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: 1rem;

		@media only screen and (min-width: $breakpoint_desktop_from) {
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: flex-end;
		}
	}

	&__offernumber-hint {
		display: flex;
		justify-content: space-between;
		color: $color-red-600;

		margin-top: 1rem;
		@media only screen and (min-width: $breakpoint_desktop_from) {
			margin-top: 0;
			align-items: center;
		}

	}

	&__offernumber-hint-icon {
		margin-right: 0.25rem;
		margin-top: 3px;
		@media only screen and (min-width: $breakpoint_desktop_from) {
			margin-top: 0;

		}
	}

	&__checkbox + &__checkbox {
		margin-top: 0.5rem;
	}
}

// hides modal while print-dialog is open
.hide-print-modal {
	.modal {
		display: none;
	}
}
