@import "../../../vendor/css/variables-canusa.scss";
@import "../../../vendor/css/common-styles.scss";

.teaser {
	background-color: $color-grey-900;
	padding-bottom: 1.5rem;
	border-radius: 0 0 2px 2px;
	@media print {
		display: none;
	}

	&__wrapper {
		overflow: hidden;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
		margin: 0 1rem;
		color: $color-grey-900;
		display: flex;
		flex-direction: column;
		position:relative;
		z-index: 3;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			flex-direction: row;
			margin: 0 1.5rem
		}
	}

	&__content {
		background-color: $color-white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
		padding: 1.5rem 1rem;
		order: 3;
		text-align: center;


		@media only screen and (min-width: $breakpoint_tablet_from) {
			border-radius: 2px 0 0 2px;
			padding: 2rem;
			width: auto;
			min-width: 50%;
			max-width: 55%;
			order: 1;
			text-align: start;
		}

		&-text {
			margin: 0.5rem 1rem 1rem 0;
		}
	}

	&__button {
		width: 100%;
		display:inline-block;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			width: auto;
		}
	}

	&__delimiter {
		position: relative;
		z-index: 1;
		order: 2;

		&-dots {
			width: 100%;
			height: 20px;
			background-image: radial-gradient($color-white 40%, rgba(255, 255, 255, 0) 50%);
			background-size: 20px 20px;
			background-repeat: repeat-x;
			position: absolute;
			background-position: -10px 0;
			margin-left: 0;
			margin-top: -10px;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				width: 20px;
				height: 100%;
				background-image: radial-gradient($color-white 40%, rgba(255, 255, 255, 0) 50%);
				background-size: 20px 20px;
				background-repeat: repeat-y;
				position: absolute;
				background-position: 0 -10px;
				margin-left: -10px;
				margin-top: 0;
			}
		}
	}

	&__image-wrapper {

		@media only screen and (min-width: $breakpoint_tablet_from) {
			width: 100%;
			max-width: 50%;
			order: 3;
		}
	}

	&__image-content{
		border-radius: 2px 2px 0 0;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			height: 100%;
		}

		img {

			@media only screen and (min-width: $breakpoint_tablet_from) {

				height: 100%;
				width: 100%;
				object-fit: cover;
				position: absolute;
				display: block;
				border-radius: 0 2px 2px 0;
			}
		}
	}

	&__subcard {
		border-radius: 0 0 2px 2px;
		width: calc(100% - 2rem);
		margin: 0 auto;
		height: 0.25rem;
		background-color: $color-white;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
		position: relative;
		z-index: 2;

		@media only screen and (min-width: $breakpoint_tablet_from) {
			border-radius: 0 0 2px 2px;
			width: calc(100% - 3.25rem);
			top: 0;
		}

		&-two {
			z-index: 1;
			height: 0.5rem;
			top: -4px;

			@media only screen and (min-width: $breakpoint_tablet_from) {
				width: calc(100% - 3.75rem);
				height: 0.25rem;
				top: 0;
			}
		}
	}
}
