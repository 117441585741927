@import "../../../vendor/css/variables-canusa.scss";

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity .3s ease;

  &--hidden {
	opacity: 0;
  }

  &__wrapper {
	overflow: auto;

	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	&::-webkit-scrollbar {
	  display: none;
	  width: 0px;
	  height: 0px;
	}

	width: 100%;
	height: 100%;
	display: flex;

	&--scrollsnap {
	  scroll-snap-type: x mandatory;
	}
  }

  &__content {
	display: flex;
	flex-wrap: nowrap;
	flex-grow: 1;
	//width: 100%;
	width: auto;
	height: 100%;

	&--snap-center {

	  & > div {
		scroll-snap-align: center;
	  }

	}

	&--snap-left {

	  & > div {
		scroll-snap-align: start;
	  }

	}
  }

  &__nav-btn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: background-color .3s linear, opacity .3s linear;
	cursor: pointer;
	background-color: $color-white;
	color: $color-grey-900;
	border-radius: $size_border_radius;
	width: auto;
	height: 3.25rem;


	&:hover {
	  color: $color-gold-700;
	}

	&--hidden {
	  opacity: 0;
	  pointer-events: none;
	}
  }

  &__nav-btn-prev {
	left: 0;
	border-radius: 0 2px 2px 0;
	box-shadow: 3px 0px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &__nav-btn-next {
	right: 0;
	border-radius: 2px 0 0 2px;
	box-shadow: -3px 0px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &__dot {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.2);
	margin-right: 0.25rem;
	transition: background-color .3s linear;
	cursor: pointer;

	&--active {
	  background-color: rgba(0, 0, 0, 0.4);
	}
  }

  &__dots {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 0.5rem;
	right: 50%;
	transform: translateX(50%);
	margin-right: -0.25rem;
  }
}
