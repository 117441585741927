@import "../../../vendor/css/variables-canusa.scss";

$block: '.notification-message';
#{$block} {
	position: fixed;
	pointer-events: none;
	display: flex;
	justify-content: flex-end;
	right: 2.5rem;
	bottom: 0;
	z-index: $zindex_consent;

	@media screen and (max-width: $breakpoint_tablet_from){
		max-height: fit-content;
		top: 6rem;
		right: 0;
	}
	@media screen and (min-width: $max_width){
		right: calc((100vw - 1850px)/2);
	}

	&__content {
		display: flex;
		position: absolute;
		border-radius: $size_border_radius;
		bottom: 2rem;
		max-width: 350px;
		background-color: $color-grey-900;
		color: $color-white;
		z-index: $zindex_consent;

		padding: 20px;
		right: -100%;
		transition: right $transition_time $transition_ease, background-color $transition_time $transition_ease;
		box-shadow: $medium_shadow;

		@media screen and (max-width: $breakpoint_tablet_from){
			bottom: unset;
			padding: 10px 15px;
			max-width: 90vw;

			width: fit-content;
		}

		&--info {
			background-color: $color-grey-900;
		}

		&--error {
			background-color: $color-red-700;

			#{$block}__icon {
				color: $color-white;
			}
		}

		&--active {
			transition: right $transition_time $transition_ease, background-color $transition_time $transition_ease;
			right: 0;
			@media screen and (max-width: $breakpoint_tablet_from){
				right: 50%;
				transform: translate(50%);
				bottom: unset;
			}
		}
	}

	&__icon {
		min-width: 24px;
		color: $color-gold-700;

		&-wrapper{
			height: 100%;
		}
	}

	&__message {
		margin-left: 1rem;
	}
}
