@import "../../../vendor/css/variables-canusa.scss";

.link {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	transition: color $transition_hover;

	position: relative;
	color: inherit;
	text-decoration: none;
	font-weight: 700;
	line-height: 1.2;

	&:hover {
		color: $color-gold-700;

		.dotted {
			&:before {
				opacity: 1;
				color: $color-gold-700;
				background-color: rgba(255, 255, 255, 0);
			}

			&:after {
				opacity: 0;
			}
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&__icon + &__label {
		margin-left: 0.5rem;
	}

	&--small{
		font-size: 0.75rem;
		.icon{
			svg {
				width: 1rem;
				height: 1rem;
			}
		}
	}
}
