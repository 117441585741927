@import "../../../vendor/css/variables-canusa";

.login_wrapper {

  .ie & {
	z-index: 1;
  }

  &__divider {
	position: relative;
	display: flex;
	margin: 2rem 0;
	@media only screen and (min-width: $breakpoint_tablet_from) {
	  margin: 3rem 0;
	}
	@media only screen and (min-width: $breakpoint_desktop_from) {
	  margin: 0;
	  background-image: linear-gradient(to bottom, currentColor 24%, rgba(255, 255, 255, 0) 0%);
	  background-position: center;
	  background-size: 1px 5px;
	  background-repeat: repeat-y;
	  padding-bottom: 0;
	  width: 1px;
	}
  }

  &__form {
	@media only screen and (min-width: $breakpoint_tablet_from) {
	  padding: 0;
	}
  }

  &__box {
	display: flex;
	flex-flow: column nowrap;
	@media only screen and (min-width: $breakpoint_desktop_from) {
	  flex-flow: row nowrap;
	  margin-bottom: 3rem;
	}
  }

  &__text {
	margin-bottom: 1rem;

	@media only screen and (min-width: $breakpoint_desktop_from) {
	  padding: 0 5.5rem 0 0;
	}
  }

  &__system-links {
	display: flex;
	flex-flow: column nowrap;

	.login_wrapper__link + .login_wrapper__link {
	  margin-top: 0.5rem;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
	  flex-flow: row nowrap;
	  justify-content: space-between;
	  .login_wrapper__link + .login_wrapper__link {
		margin-top: 0;
	  }
	}
  }

  &__gender-sensitive-disclaimer {
	margin-top: 4rem;
	color: $color-grey-400;
  }

  @media only screen and (min-width: $breakpoint_desktop_from) {

	&__text {
	  order: 1;
	}

	&__divider {
	  order: 2;
	}

	&__mask-content {

	  order: 3;

	  @media only screen and (min-width: $breakpoint_desktop_from) {
		padding: 0 0 0 5.5rem;
	  }
	}
  }

  &__text,
  &__mask-content {
	display: flex;
	flex-flow: column nowrap;

	a {
	  text-align: center;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
	  flex-grow: 1;
	  flex-shrink: 1;
	  flex-basis: 0;
	}
  }

  &__success-headline {
	margin-bottom: 1rem;
  }

  &__success-mask-content {
	margin-bottom: 1.5rem;
  }

  &__headline {
	margin-bottom: 2.5rem;

	+ p {
	  margin-bottom: 2rem;
	}

	&--linebreak {
	  margin: 0;
	}

	&--visible-on-desktop {
	  visibility: hidden;
	  display: none;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
	  visibility: hidden;
	  &--visible-on-desktop {
		display: block;
		visibility: visible;
	  }
	}
  }

  &__inputs {
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: column;

	> * + * {
	  margin-top: 1rem;
	}
  }

  &__link {
	display: inline-block;
	margin-bottom: 0.5rem;
	align-self: center;

	&:last-child {
	  margin-bottom: 0;
	}
  }

  &__submit {
	margin-bottom: 1rem;
  }
}

