@import "../../../../../vendor/css/variables-canusa";

.travel-detail__wrapper {
	display: flex;
	justify-content: space-between;
	flex-flow: column nowrap;
	padding: 1.5rem 0;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		flex-flow: row nowrap;
	}

	.travel-detail {
		display: inline-flex;
		align-items: center;
		border: 1px solid $color-grey-100;
		border-radius: 2px;
		flex-grow: 1;
		margin-right: 1rem;
		max-width: 100%;
		width: 100%;

		& + .travel-detail {

			border-top: none;
			@media only screen and (min-width: $breakpoint_tablet_from){
				border-top: 1px solid $color-grey-100;
			}
			margin-right: 0;
		}

		.travel-detail__textwrapper {
			padding: 1rem;
			border-right: 1px solid $color-grey-100;
			font-size: 0.875rem;

			flex-grow: 1;

			&__label {
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		.travel-detail__icon {
			font-size: 1.5rem;
			padding: 1rem 0.5rem;
		}
	}
}
