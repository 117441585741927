@import "../../../vendor/css/variables-canusa.scss";

@mixin btnSmall() {
	padding: 0.6rem 1.5rem;
}

@mixin btnMedium() {
	padding: 0.875rem 1.5rem;
	font-size: 0.875rem;
	letter-spacing: 0.5px;
}

@mixin btnLarge() {
	padding: 1.125rem 1.5rem;
	font-size: 1rem;
	letter-spacing: 0.57px;
}

@mixin btn() {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 0.25rem;
	line-height: 1rem;
	font-weight: bold;
	font-family: $fontFamily;

	&__label {
		white-space: nowrap;
	}

	@include btnMedium();

	text-transform: uppercase;

	transition: all $transition_time ease;

	// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
	@include btnModifier();

	&:hover {
		text-decoration: none;
		border-radius: 0.125rem;
	}

	&:focus {
		outline: none;
	}
}

// default-appearance is secondary
@mixin btnModifier(
	$bgColor:$color-gold-700,
	$bgColorHover:$color-gold-600,
	$borderColor:$color-gold-700,
	$borderColorHover:$color-gold-600,
	$textColor:$color-white,
	$textColorHover:$color-white
) {

	border: 2px solid $borderColor;
	color: $textColor;
	background-color: $bgColor;
	&:hover {
		color: $textColorHover;
		background-color: $bgColorHover;
		border-color: $borderColorHover;
	}

	&:active,
	&:visited {
		color: $textColor;
	}
}

@mixin fullWidthButton() {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.cta-button,
.button {
	@include btn();

	&__icon + &__label {
		margin-left: 0.5rem;
	}

	&--important, // legacy button class
	&--primary {
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier($color-red-700, $color-red-600, $color-red-700, $color-red-600, $color-white, $color-white);
	}

	&--secondary {
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier($color-gold-700, $color-gold-600, $color-gold-700, $color-gold-600, $color-white, $color-white);
	}

	&--tertiary {
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier($color-white, $color-gold-050, $color-gold-600, $color-gold-600, $color-gold-800, $color-gold-800);
	}

	&--quaternary {
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier($color-yellow-700, $color-yellow-600, $color-yellow-700, $color-yellow-600, $color-white, $color-white);
	}

	&--ghost {
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier(transparent, $color-gold-700, $color-gold-700, $color-gold-700, $color-gold-700, $color-white);
	}

	&--main-navigation {
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier($color-white, $color-gold-050, $color-gold-600, $color-gold-600, $color-gold-800, $color-gold-800);
		border: none;
		border-radius: 0;
	}


	// special styling for linkbutton
	&--link {
		color: $color-grey-900;
		padding: 0;
		border: none;
		background-color: transparent;
		text-transform: none;
		height: 1rem;
		font-weight: bold;
		width: fit-content;

		&.dotted {
			&:before,
			&:after {
				width: 100%;
			}
		}

		&:hover,
		&:focus,
		&:active {
			color: $color-gold-700;
			background-color: transparent;
		}
	}


	&--small {
		@include btnSmall();
	}

	&--medium {
		@include btnMedium();
	}

	&--large {
		@include btnLarge();
	}

	&[disabled],
	&--disabled {
		cursor: default;
		pointer-events: none;
		// bgColor - bgColorHover - borderColor - borderColorHover - textColor - textColorHover
		@include btnModifier($color-grey-200, $color-grey-200, $color-grey-200, $color-grey-200, $color-grey-600, $color-grey-600);
	}

	&--fit {
		@include fullWidthButton();
		@media only screen and (min-width: 768px) {

			max-width: fit-content;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			white-space: nowrap;
			@supports (-moz-appearance:none) {
				width: -moz-max-content;
			}
		}
	}

	//TODO: unify btn-fixed & btn--fix if possible
	&--fix {
		@include fullWidthButton();
		@media only screen and (min-width: 768px) {

			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px; // @TODO: This value is arbitrary and needs to be discussed!
			margin: 0 auto 20px;
		}
	}

	&--fixed {
		@include fullWidthButton();
		@media only screen and (min-width: 768px) {
			white-space: nowrap;
			width: calc(#{$column-4} + 4px);
		}
	}

	&--fullwidth,
	&--full {
		@include fullWidthButton();
	}

	&--loading {
		span {
			position: relative;
			display: flex;
			align-items: center;

			&:before {
				content: '';
				width: 1rem;
				height: 1rem;
				border: 2px solid currentColor;
				position: absolute;
				border-radius: 50%;
				border-left-color: transparent;
				left: -1.5rem;
				top: -1px;
				animation: rotation 1s linear infinite;
				opacity: 0.7;
			}
		}
	}
}

.cta-button {
	margin-bottom: 1.25rem;

	&--moreSpace {
		margin-top: 4em;
		margin-bottom: 4em;
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
