@import "variables-canusa.scss";

*, *:before, *:after {
	box-sizing: inherit;
}

_:-ms-input-placeholder, :root a div {
	background-image: url("iehack:///");
}

@font-face {
	font-family: 'Market Pro';
	src: url('../../assets/fonts/canusa/MarketPro.eot'); /* IE9 Compat Modes */
	src: url('../../assets/fonts/canusa/MarketPro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8*/
	url('../../assets/fonts/canusa/MarketPro.woff') format('woff'), /* Modern Browsers*/
	url('../../assets/fonts/canusa/MarketPro.ttf') format('truetype'), /* Safari, Android, iOS*/
	url('../../assets/fonts/canusa/MarketPro.svg#MarketPro') format('svg'); /* Legacy iOS */
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* roboto-regular - latin-ext_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../../assets/fonts/roboto/roboto-v29-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	max-width: $content_max_width;
	margin: 0 auto;
	background-color: $color-white;
}

.page-full-height {
	display: flex;
	flex-flow: column nowrap;
	flex: 1 1;

	.ie & {
		display: block;
	}
}

main {
	flex: 1 1 auto;
}

body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden !important;

	font-family: $fontFamily;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.43;
	color: $color-grey-900;
	text-align: left;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: $color-grey-100;
}

.round-border {
	border-radius: $size_border_radius;
}

.shadow {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.item-shadow {
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.img-shadow {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.6);
}

img::selection {
	background: none;
}

.is-transparent {
	background-color: rgba(0, 0, 0, .6);
}

.centered-content {
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;

	display: flex;
	flex-flow: column nowrap;
	flex: 1;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		margin: 0 1.5rem;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
		margin: 0 2rem;
	}

	@media only screen and (min-width: 1344px) {
		margin: 0 auto;
	}
}

.lock-scroll {
	overflow: hidden;
}

h1, h2, h3, h4, h5, h6,
ul, li, p {
	margin: 0;
	padding: 0;
}

hr {
	margin-left: auto;
	margin-right: auto;
}

//---------------------------------------------------- FONT STYLING ----------------------------------------------------

.ts {
	&-handwriting {
		font-family: 'Market Pro', Helvetica, "Helvetica Neue", Arial, sans-serif;
		font-weight: 400;
		line-height: 100%;
		letter-spacing: normal;
		text-transform: none;
	}

	&-bold {
		font-weight: bold;
	}

	&-uppercase {
		text-transform: uppercase;
	}

	&-copy-medium {
		@include copy-medium;
	}

	&-copy-small {
		@include copy-small;
	}
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	text-transform: uppercase;

	&.ts-handwriting,
	&.tt-none {
		text-transform: none;
	}
}

.h1, h1 {
	@include h1;

	&.ts-dynamic {
		@media (max-width: $breakpoint_tablet_to) {
			@include h2;
		}
	}

	&.ts-handwriting {
		@include h1-handwriting;

		&.ts-dynamic {
			@media (max-width: $breakpoint_tablet_to) {
				@include h2-handwriting;
			}
		}
	}
}

.h2, h2 {
	@include h2;

	&.ts-dynamic {
		@media (max-width: $breakpoint_tablet_to) {
			@include h3;
		}
	}

	&.ts-handwriting {
		@include h2-handwriting;

		&.ts-dynamic {
			@media (max-width: $breakpoint_tablet_to) {
				@include h3-handwriting;
			}
		}
	}
}

.h3, h3 {
	@include h3;

	&.ts-dynamic {
		@media (max-width: $breakpoint_tablet_to) {
			@include h4;
		}
	}

	&.ts-handwriting {
		@include h3-handwriting;
	}
}

.h4, h4 {
	@include h4;

	&.ts-dynamic {
		@media (max-width: $breakpoint_tablet_to) {
			@include h5;
		}
	}
}

.h5, h5 {
	@include h5;
}

.dotted {
	position: relative;

	&:before,
	&:after {
		transition: opacity 0.1s;
		content: '';
		position: absolute;
		left: 0;
		bottom: 1px;
		width: 100%;
		height: 1px;
		background-color: rgba(0, 0, 0, 0);
		background-image: linear-gradient(to right, currentColor 24%, rgba(255, 255, 255, 0) 0%);
		background-position: bottom;
		background-size: 3px 1px;
		background-repeat: repeat-x;
	}

	&:before {
		opacity: 0;
	}

	&:after {
		opacity: 1;
	}
}

.dot {
	position: relative;
	padding: 0 8px;

	&:before {
		content: '\2022';
	}
}

a {
	color: inherit;
	text-decoration: none;
}

textarea {
	line-height: 1.43;
}

input, select, textarea {
	appearance: none;
	padding: 0.875rem 1rem;
	border-width: 1px;
	border-radius: 2px;
	border-color: $color-gold-400;
	font-size: 0.875rem;
	text-align: left;
	color: inherit;
	border-style: solid;
	background-color: white;

	&.is-passive {
		color: $color-grey-900;
		background-color: $color-grey-100;
	}

	&:hover,
	&:focus {
		border-color: $color-gold-700;
	}

	&[readonly] {
		opacity: 0.5;
		pointer-events: none;
	}

	.field-error & {
		border-color: $color-red-600;

		&:focus {
			border-color: $color-red-600;
		}
	}
}

input, select, textarea, button, a, label {
	-webkit-tap-highlight-color: transparent;
	outline: none;

	&:focus {
		border-color: $color-gold-700;
	}
}


$perforation: '/assets/img/dot-for-perforation.svg';

.perforation {

	&-top:before,
	&-bottom:after {
		content: "";

		background-image: url(#{$perforation});
		background-repeat: space no-repeat;
		background-position: center;
		height: 0.25rem;
		position: absolute;
		left: -0.178rem;
		width: calc(100% + 0.178rem);
	}

	&-top:before {
		top: 0;
		transform: translateY(-50%);
	}

	&-bottom:after {
		bottom: 0;
		transform: translateY(50%);
	}
}

.box {
	position: relative;
	background-color: $color-white;
	border-radius: $size_border_radius;
	box-shadow: $slight_shadow;

	/*STANDARD SIZE*/
	padding: 1rem;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		padding: 2.5rem;
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
		padding: 3rem;
	}

	@media print {
		border: 1px $color-grey-400 solid;
		padding: 1.5rem;
		box-shadow: none !important;
	}

	&--large {
		@media only screen and (min-width: $breakpoint_tablet_from) {
			padding: 5.125rem;
		}
	}
}

.box + .box {
	margin-top: 1rem;
}

.dotted-headline-small,
.dotted-headline {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	&:before,
	&:after {
		content: "";
		display: flex;
		flex-grow: 1;

		background-image: linear-gradient(to right, currentColor 24%, rgba(255, 255, 255, 0) 0%);
		background-position: bottom;
		background-size: 4px 1px;
		background-repeat: repeat-x;

	}

	&:before {
		margin-right: 15px;
	}

	&:after {
		margin-left: 15px;
	}
}

.grid {
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	grid-column-gap: 0.5rem;
	align-items: center;

	@media only screen and (min-width: $breakpoint_tablet_from) {
		grid-template-columns: repeat(16, 1fr);
		grid-column-gap: 0.75rem
	}

	@media only screen and (min-width: $breakpoint_desktop_from) {
		grid-column-gap: 1rem
	}
}

.breaker {
	display: block;
	width: 100%;
	height: 0;
}

.disabled {
	pointer-events: none;
	cursor: default;
}

@media print {

	.no-print {
		display: none;
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

//Preloader Pulsing
@keyframes pulsate {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 0.7;
	}
	100% {
		opacity: 0.5;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slideIn {
	0% {
		left: 100%;
	}
	100% {
		left: 0;
	}
}

@keyframes slideOut {
	0% {
		left: 0;
	}
	100% {
		left: 100%;
	}
}


//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

@media print {
	body{
		background-color: transparent;
	}
}
