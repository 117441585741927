@import "../../../vendor/css/variables-canusa.scss";

$route-description: '.route-description';

#{$route-description} {
	background-color: rgba(153, 153, 153, 0.1);
	padding: 0 1rem;

	@media screen and (min-width: $breakpoint_tablet_from), print {
		background-color: transparent;
		padding: 0;
	}

	//----------------------------------------------------- HEADER -----------------------------------------------------

	&__header {
		display: flex;
		flex-wrap: wrap;
		padding: 1rem 0;
		cursor: pointer;
		transition: color .3s linear;

		&:hover {
			color: $color-gold-700
		}
	}

	&__duration {
		display: flex;
		align-items: center;

		@media screen and (min-width: $breakpoint_tablet_from), print {
			min-width: 7rem;
			order: -1;

			&:after {
				content: '';
				flex: 1 1 auto;
				height: 1px;
				background-color: $color-grey-400;
				margin: 0 0.5rem;
			}
		}
	}

	&__collapsible {
		margin-left: auto;

		&--open {
			display: inline-block;
			width: 8px;

		}

		&--close {
			display: none;
			width: 8px;
		}
	}

	&__label {
		@media screen  and (max-width: $breakpoint_tablet_to){
			flex: 1 1 100%;
			border-top: 1px solid grey;
			margin-top: 1rem;
			padding-top: 1rem;
		}

		@media screen and (min-width: $breakpoint_tablet_from), print {
			order: -1
		}
	}

	//---------------------------------------------------- CONTENT -----------------------------------------------------

	&__content {
		transition: .5s cubic-bezier(0, 1, 0, 1);
		overflow: hidden;
		max-height: 0;
	}

	&__section {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 1.25rem;
		}
	}

	&__headline {
		margin-bottom: 0.25rem;
	}

	//------------------------------------------------ COLLAPSIBLE MAGIC -----------------------------------------------

	input[type="checkbox"] {
		display: none;

		&:checked {
			~ #{$route-description}__header {
				#{$route-description}__collapsible {
					&--open {
						display: none;
					}

					&--close {
						display: inline-block;
					}
				}
			}

			~ #{$route-description}__content {
				max-height: 800px;
				transition: max-height 1s ease-in-out;
			}
		}
	}

	//--------------------------------------------------- PRINT ONLY ---------------------------------------------------

	@media print {
		&__collapsible {
			display: none;
		}

		.print-routes & {
			.route-description__content {
				display: block;
				transition: unset;
				animation-duration: 0s;
				animation: unset;

				max-height: unset;
				min-height: 100%;
				height: 100%;
			}
		}
	}
}
