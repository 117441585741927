@import "../../../vendor/css/variables-canusa.scss";


.routes-connector {
  padding: 0 0 1.5rem;
  margin-bottom: 0.5rem;

  @media only screen and (min-width: $breakpoint_tablet_from) {
	margin-bottom: 0;
  }

  &__columns {
	display: flex;
	flex-flow: column nowrap;
	@media only screen and (min-width: $breakpoint_large_desktop_from) {
	  flex-flow: row nowrap;
	}
  }

  &__hint-text {
	font-weight: bold;
	margin-bottom: 1.5rem;

	p + p {
	  margin-top: 0.5rem;
	}

	@media only screen and (min-width: $breakpoint_tablet_from) {
	  padding-right: 2rem;
	}
  }

}
