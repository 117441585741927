@import "../../../vendor/css/variables-canusa.scss";

.alternative-teaser-list {
	display: flex;
	margin: 0.5rem 0;

	&__remaining-items {
		width: 140px;
		height: 80px;
		border: 1px solid $color-grey-100;
		display: inline-grid;
		justify-items: center;
		align-items: center;
		color: $color-grey-800;
	}
}
